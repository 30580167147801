import React, { Component } from 'react'
import ReactGA from 'react-ga'
import history from './service/history'
import { Route, Switch, Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import DefaultLayout from './containers'
import NotSupportedBrowser from './views/pages/not-supported-browser'
import WorksSiding from './views/pages/works-siding'
import Login from './views/pages/login'
import ResetPassword from './views/pages/reset-password'
import ForgotPassword from './views/pages/forgot-password'
import Page404 from './views/pages/page404'
import Page500 from './views/pages/page500'
import PrivateRoute from './views/utils/privateRoute'
import Terms from './views/pages/membership-terms'
import { getTrackingId } from './api/tracking-id'
import { axiosInstance } from './lib/axios'

function setAxiosInterception() {
  //https://sabljakovich.medium.com/axios-response-time-capture-and-log-8ff54a02275d
  axiosInstance.interceptors.request.use((x) => {
    // to avoid overwriting if another interceptor
    // already defined the same object (meta)
    x.meta = x.meta || {}
    x.meta.requestStartedAt = new Date().getTime()
    return x
  })
}

async function fetchTrackingId() {
  const result = await getTrackingId()
  if (result.data) {
    ReactGA.initialize(result.data.portalGoogleAnalyticsTrackingId)
    ReactGA.pageview(window.location.pathname)
    setAxiosInterception()
    history.listen((location) => {
      ReactGA.set({ page: location.pathname })
      ReactGA.pageview(location.pathname)
    })
  } else {
    console.error(result.err)
  }
}

class App extends Component {
  componentDidMount() {
    fetchTrackingId()
  }

  render() {
    return (
      <div>
        <ToastContainer autoClose={8000} />
        <Router history={history}>
          <Switch>
            <Route
              exact
              path="/nao-suportado"
              name="Not supported browser"
              component={NotSupportedBrowser}
            />
            <Route
              exact
              path="/login/forgot-password"
              name="Forgot Password Page"
              component={ForgotPassword}
            />
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route
              exact
              path="/reset-password/:uuid"
              name="Reset Password Page"
              component={ResetPassword}
            />
            <Route exact path="/senha/:uuid" name="Reset Password Page" component={ResetPassword} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route exact path="/terms" name="Termos de Adesão" component={Terms} />
            <PrivateRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </Router>
      </div>
    )
  }
}

export default App
