import { DEFAULT_STORE, LIST_BRANCH, IS_IPP } from '../actionTypes/branch'

const BASE = {
  state: false,
  data: null
}

const INITIAL_STATE = {
  listBranch: BASE,
  defaultStore: BASE,
  isIPP: BASE
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DEFAULT_STORE:
      return { ...state, defaultStore: action.payload }
    case LIST_BRANCH:
      return { ...state, listBranch: action.payload }
    case IS_IPP:
      return { ...state, isIPP: action.payload }
    default:
      return state
  }
}
