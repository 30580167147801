import React from 'react'

export const questions = [
  {
    title: 'Como alterar meus dados cadastrais?',
    Description: () => (
      <div>
        {' '}
        <p>
          Atualmente, qualquer alteração de dados cadastrais e bancários, são realizados através da Central de Atendimento KMV.
        </p>
        <p>
          Você pode abrir um chamado direto pelo Chat de Atendimento, localizado no canto inferior direito do Portal ou pelos telefones 3003-3470 ou 0800 707-6656.
        </p>
        <p>Caso opte por entrar em contato por telefone, nossos horários de atendimento são:</p>
        <p>Segunda a sexta, das 09h às 21h</p>
        <p>Sábados, das 09h às 18h</p>
        <i>
          {' '}
          Não esqueça de encaminhar os documentos comprobatórios em caso de alteração de dados sensíveis, como dados contratuais e bancários. O procedimento só é realizado com o envio das documentações dentro dos padrões de segurança.
        </i>{' '}
      </div>
    ),
    panel: 'panel1'
  },
  {
    title: 'Esqueci minha senha, como proceder?',
    Description: () => (
      <div>
        {' '}
        <p>
          Caso você tenha esquecido sua senha, pode solicitar o reset de senha através da tela de
          login.
        </p>
        <p>
          Clique no link "Esqueci minha senha", que fica embaixo do campo de "senha" no formulário de login. Você receberá um e-mail com as instruções para resetar e atualizar sua senha.
        </p>
      </div>
    ),
    panel: 'panel2'
  },
  {
    title: 'Não recebi o e-mail de reset de senha, o que faço?',
    Description: () => (
      <div>
        {' '}
        <p>
          O primeiro passo é conferir na sua caixa de SPAM. Nosso e-mail é institucional e muitos sistemas anti-fraude pode nos identificar como SPAM ou publicidade enganosa.
        </p>
        <p>
          Caso o e-mail não esteja no SPAM, entre em contato com a Central de Atendimento KMV, para verificarmos o que pode ter ocorrido, pelos telefones 3003-3470 ou 0800 707-6656.
        </p>
        <p>Caso opte por entrar em contato por telefone, nossos horários de atendimento são:</p>
        <p>Segunda a sexta, das 09h às 21h</p>
        <p>Sábados, das 09h às 18h</p>
        <b> Importante: </b>{' '}
        <p>
          Ressaltamos que em casos de provedor particular de e-mail, pode haver restrições internas que bloqueiam nossos e-mails e não são enviados nem para a caixa de SPAM. Todos os nossos contatos por e-mail são feitos de forma segura.
        </p>
      </div>
    ),
    panel: 'panel3'
  },
  {
    title: 'Como buscar transações específicas?',
    Description: () => (
      <div>
        {' '}
        <p>
          No canto superior, de todas as telas, há um campo de busca onde é possível encontrar uma transação de forma direta e específica. Basta digitar o código da transação e o Portal te redirecionará para os detalhes dessa transação.
        </p>
        <p>
          Além disso, você pode localizar a transação através da aba “vendas fechadas”. Nessa sessão você encontrará um filtro que permite selecionar um período pré-determinado (último mês, este mês, última semana etc.) ou um período personalizado, em seguida, basta clicar no número total de vendas - um link azul na coluna “total de vendas” - que te redirecionará para a lista de transações.
        </p>
      </div>
    ),
    panel: 'panel4'
  },
  {
    title: 'A onde consigo acompanhar minhas transações?',
    Description: () => (
      <div>
        {' '}
        <p>
          Para acompanhar as transações do dia basta acessar, pelo menu de navegação principal, a aba "Vendas de hoje".
        </p>
        <p>
          Nessa tela poderá acompanhar em tempo real as vendas e seus valores. Além disso, é possível verificar status desses pagamentos, assim identificando transações estornadas ou canceladas.
        </p>
        <p>
          Para acompanhar as transações de outros períodos personalizados é só acessar a aba "Vendas fechadas". Lá você encontrará um filtro que permite selecionar um período pré-determinado (último mês, este mês, última semana, etc) ou um período personalizado. Em baixo, o sistema listará cada dia de cada CNPJ com os valores totais daquela data e o número total de vendas.
        </p>
        <p>
          Para acompanhar venda por venda de um dia específico, basta clicar no número total de vendas - um link azul - que te redirecionará para a lista de transações.
        </p>
        <p>Em todas essas telas também apresentamos os valores totais do período, visualização a data de previsão do recebimento e você pode ainda extrair um relatório detalhado ou consolidado.</p>
      </div>
    ),
    panel: 'panel5'
  },
  {
    title: 'Como vejo as transações de uma data específicas?',
    Description: () => (
      <div>
        <p>
          Para localizar uma venda específica, ou um período personalizado, basta acessar a aba "Vendas fechadas". Lá você encontrará um filtro que permite selecionar um período pré-determinado (último mês, este mês, última semana etc.) ou um período personalizado e clicar no número total de vendas - um link azul na coluna “total de vendas” - que te redirecionará para a lista de transações.
        </p>
        <p>
          Caso não consiga encontrar uma determinada transação, entre em contato com a Central de Atendimento para entender o que pode ter ocorrido.
        </p>
        <p>
          No geral, se a transação não está no Portal, é porque nem chegou a ser registrada pela operadora, mas caso durante o fluxo de pagamento o slip dessa transação tenha sido impresso corretamente, entre em contato com o nosso time!
        </p>
      </div>
    ),
    panel: 'panel6'
  },
  {
    title: 'Como visualizo apenas transações de uma determinada loja?',
    Description: () => (
      <div>
        <p>
          Caso seu posto faça parte de um Grupo Comercial, econômico ou sociedade com mais de uma unidade, temos um filtro, no canto esquerdo da tela que permite escolher o CNPJ que deseja visualizar as informações.
        </p>
        <p>
          Assim você conseguirá filtrar as informações em tela e visualizá-las de loja por loja.
        </p>
        <p>
          Caso deseje visualizar todo conteúdo de todas as lojas, basta desativar o filtro selecionando "Selecionar um CNPJ".
        </p>
        <p>
          Por padrão sempre apresentamos a informação da Matriz, caso desejar visualizar de outra unidade basta seguir a explicação acima para ajuste do filtro.
        </p>
      </div>
    ),
    panel: 'panel7'
  },
  {
    title: 'Como visualizo as transações de todas as minhas lojas?',
    Description: () => (
      <div>
        <p>
          Caso desejar visualizar todo conteúdo de todas as lojas, basta desativar o filtro selecionando "Selecionar um CNPJ".
        </p>
        <p>
          Por padrão sempre apresentamos a informação da Matriz, melhorando a performance de retorno do site inteiro. São muitas informações armazenadas de forma segura e puxar todas de uma vez ao acessar uma página pode afetar o tempo de carregamento do site. Por isso é necessário desativar o filtro quando quiser ver tudo de todas as lojas.
        </p>
      </div>
    ),
    panel: 'panel77'
  },
  {
    title: 'Qual é o prazo para repasse dos pagamentos realizados via KMV?',
    Description: () => (
      <div>
        <p>
          Os reembolsos das transações realizadas com o app KMV ocorrem de forma padrão em D+31 e o valor é enviado para a conta cadastrado durante o aceite ao programa. Caso desejar receber os valores no formato D+3, você pode entrar em contato com a nossa Central de Atendimento (tel: 3003-3470) solicitando essa alteração.
        </p>
        <p>
          ❗ Atualmente há uma taxa para a antecipação dos recebíveis em D+3, para mais detalhes é importante que você contate nosso time para mais informações.
        </p>
        <p>
          ❗ O pagamento do KMV será realizado via TED ou PIX para sua conta digital e não mais vinculado a uma nota fiscal de combustível.
        </p>
      </div>
    ),
    panel: 'panel8'
  },
  {
    title: 'Como funciona o repasse da taxa de repasse?',
    Description: () => (
      <div>
        <p>
          A taxa de repasse das transações varia conforme a penetração do seu posto. A penetração é o termo usado que considera quanto de combustível Ipiranga foi vendido através do app KMV. Quanto mais clientes utilizarem o serviço KMV em seu posto, menor será a taxa de cobrança após o período de apuração.
        </p>
        <p>
          Abaixo estão as taxas de acordo com o percentual de penetração do seu posto:
        </p>
        <p>
          -Penetração até 2%: taxa de 2,39%
        </p>
        <p>-Penetração de 2% até 10%: taxa de 1,89% </p>
        <p>
          -Penetração acima de 10%: taxa de 1,59%
        </p>
        <p>
          É importante lembrar que a apuração da penetração é realizada separadamente por tipo de pagamento. Ou seja, haverá uma apuração para transações em dinheiro e outra para outros meios de pagamento (cartão ou saldo do app).
        </p>
        <p>Além disso, as taxas mencionadas são para o repasse em D+31. Se optar pelo repasse com modalidade de D+3, haverá um adicional de 1,40% sobre o valor mencionado anteriormente.</p>
      </div>
    ),
    panel: 'panel9'
  },
  {
    title: 'Como funciona o repasse de pagamentos em dinheiro via KMV?',
    Description: () => (
      <div>
        <p>
          Quando o pagamento é feito em dinheiro, através do app, a taxa de revenda cobrado pode variar de acordo com a penetração em que o posto se encontra. Além disso, o desconto é feito sobre o valor total do abastecimento.
        </p>
        <p>
          Nessa modalidade o cliente realiza o pagamento em espécie durante o abastecimento e utiliza o app apenas para acumular Pontos KMV ou receber cashback. Portanto, há apenas a cobrança da taxa e nenhum repasse pendente.
        </p>
        <p>
          Exemplo: Em um abastecimento de R$ 100 realizado no dinheiro via KMV, se a revenda está na faixa de 1,59% na taxa de repasse, será descontado R$ 1,59 dessa transação, e o valor restante deve ser recebido em espécie no ato do pagamento.
        </p>
        <p>
          No Portal Parceiros, você tem acesso a todas as informações sobre as transações, acessando a aba de "vendas fechadas".
        </p>
      </div>
    ),
    panel: 'panel10'
  },
  {
    title: 'Preciso alterar o meu login do Portal Parceiros',
    Description: () => (
      <div>
        <p>
          O login de acesso ao Portal Parceiros KMV é feito através do e-mail cadastrado no momento do aceite ao KMV.
        </p>
        <p>
          Caso você precise alterar o e-mail de acesso, entre em contato com nosso time através da Central de Atendimento, no telefone 3003-3470, para realizar a alteração do e-mail.
        </p>
      </div>
    ),
    panel: 'panel11'
  },
  {
    title: 'O que fazer em caso de duplicidade no pagamento com o KMV?',
    Description: () => (
      <div>
        <p>
          Se você possui duas cobranças no mesmo valor com status “finalizado”, uma delas será cancelada. A venda recebida em duplicidade, quando for cancelada, será retirada dos próximos repasses.
        </p>
        <p>
          Para identificar as vendas, acesse o seu extrato através do Portal Parceiros KMV, e caso o status da transação duplicada esteja como “estornada”, abra um chamado com o nosso time.
        </p>
      </div>
    ),
    panel: 'panel12'
  },
  {
    title: 'O que fazer quando o slip não é impresso em uma venda?',
    Description: () => (
      <div>
        <p>
          Para considerar uma transação aprovada, o slip precisa ser impresso durante o fluxo de abastecimento do cliente, ou posteriormente através da reimpressão.
        </p>
        <p>
          Caso não haja a emissão do slip, recomendamos que a transação seja refeita pelo cliente, uma vez que ela será cancelada automaticamente em até 48 horas no Portal Parceiros.
        </p>
        <p>
          Se o erro persistir, abra um chamado com nosso time!
        </p>
      </div>
    ),
    panel: 'panel13'
  },
  {
    title: 'O que devo fazer caso uma transação tenha o status "estornada", mas houve a emissão do slip no pagamento?	',
    Description: () => (
      <div>
        <p>
          Caso uma transação seja cancelada mesmo com a emissão do slip, entre em contato com o nosso time e encaminhe as informações abaixo para análise:
        </p>
        <p>
          - Foto ou print do slip impresso
        </p>
        <p>
          - Código
        </p>
        <p>
          - Valor
        </p>
        <p>
          - Data e hora da transação.
        </p>
        <p>
          Lembrando que, para as revendas que utilizam o app KMV via Ipiranga Online, através da POS Smart da REDE, no dia 19/09/2023 a versão 1.1.10 do Ipiranga online foi lançada com a correção de transações que eram canceladas posterior a sua finalização. É muito importante realizar a atualização da sua POS seguindo o passo a passo abaixo:
        </p>
        <p>
          1. Abrir a Rede Store
        </p>
        <p>
          2. Acessar Meus Apps
        </p>
        <p>
          3. Abrir Ipiranga Online
        </p>
        <p>
          4. Clicar no botão Atualizar (caso não esteja disponível, desinstale e instale novamente)
        </p>
        <p>
          <strong>Obs.:</strong> A versão do app deve aparecer na tela inicial após realizar a atualização
        </p>

      </div>
    ),
    panel: 'panel14'
  },
  {
    title: 'Posso ter um acesso único para todos os CNPJ do meu grupo?',
    Description: () => (
      <div>
        <p>
          Sim! Para isso, é necessário utilizar o mesmo login de acesso (e-mail) para todos os CNPJs do grupo.
        </p>
        <p>
          Caso seja necessário alterar esses dados, entre em contato com o nosso time e encaminhe o e-mail desejado e todos os CNPJ que deverão ser consolidados em um login único.
        </p>
      </div>
    ),
    panel: 'panel15'
  },
  {
    title: 'O valor do cashback recebido pelo cliente impacta no valor repassado?',
    Description: () => (
      <div>
        <p>
          O cashback que o seu cliente receber durante o pagamento, através do app KMV, não impacta no valor que será repassado por essa venda. A taxa de repasse varia de acordo com a penetração do posto (em M-2) e possui faixas específicas de cobranças, de acordo com o meio de pagamento, conforme os parâmetros abaixo:
        </p>
        <p>
          -Penetração até 2%: taxa de 2,39%
        </p>
        <p>
          -Penetração de 2% até 10%: taxa de 1,89%
        </p>
        <p>
          -Penetração acima de 10%: taxa de 1,59%
        </p>

        <p>
          <strong>Importante:</strong> Para pagamentos em dinheiro, o VIP terá que receber esse valor em mãos durante o abastecimento. Portanto, você verá que não haverá repasse pendente e descontaremos apenas o valor da taxa vigente, uma vez que o pagamento foi recebido em espécie.
        </p>
      </div>
    ),
    panel: 'panel16'
  },
  {
    title: 'Como faço a alteração de dados bancários de repasse?',
    Description: () => (
      <div>
        <p>
          Para alterar seus dados bancários de repasse no KMV, abra um chamado, para efetuarmos a alteração em seus dados bancários, pedimos que nos envie o comprovante bancário contendo seu CNPJ, assim como as informações completas de sua conta (Banco, Agência e número da conta com o dígito).
        </p>
        <p>
          É importante ressaltar que a modificação bancária só pode ser realizada para contas vinculadas ao mesmo CNPJ que solicitou a alteração. No caso de o CNPJ ser diferente, é necessário que corresponda à matriz do seu grupo.
        </p>
      </div>
    ),
    panel: 'panel17'
  },
  {
    title: 'Como faço a alteração de sócio do meu posto?',
    Description: () => (
      <div>
        <p>
          Para realizar a alteração no seu cadastro do KMV, por favor, abra um chamado e especifique qual dado deseja alterar. É importante que você encaminhe documentos comprobatórios que confirmem a mudança de sócio, neste caso o contrato social atualizado.
        </p>
      </div>
    ),
    panel: 'panel18'
  },
  {
    title: 'Como faço a alteração do endereço do meu posto?',
    Description: () => (
      <div>
        <p>
          Para efetuar a alteração do endereço da sua unidade no seu cadastro do KMV, solicitamos que abra um chamado e nos envie o seu novo endereço, informações de latitude e longitude, juntamente com o contrato social que comprove a mudança.
        </p>
      </div>
    ),
    panel: 'panel19'
  },
  {
    title: 'Como faço a alteração do meu número de telefone?',
    Description: () => (
      <div>
        <p>
          Para efetuar a alteração do telefone da sua unidade no seu cadastro do KMV, solicitamos que abra um chamado e nos envie o seu novo número de telefone, especificando se a alteração será no cadastro do revendedor ou do posto.
        </p>
      </div>
    ),
    panel: 'panel20'
  },
  {
    title: 'Como faço a alteração do meu e-mail no cadastro do KMV?',
    Description: () => (
      <div>
        <p>
          Para efetuar a alteração no seu cadastro do KMV, é necessário que você abra um chamado, nos enviando o seu novo endereço de e-mail, acompanhado do comprovante de titularidade da conta de e-mail (em formato de print).
        </p>
        <p>
          Importante lembrar que ao alterar o e-mail dentro do sistema do KMV, você muda também o login de acesso ao Portal Parceiros KMV!
        </p>
      </div>
    ),
    panel: 'panel21'
  },
  {
    title: 'O que devo fazer caso um cliente sinalize dificuldades durante o uso do app em meu posto?',
    Description: () => (
      <div>
        <p>
          O KMV possui uma central exclusiva para seus consumidores, e é muito importante direcionar o cliente para abertura de chamado com nosso time através 0800 283 0444 ou 3003 3424, que é nosso canal oficial e seguro para tratativa e troca de informações importantes.
        </p>
      </div>
    ),
    panel: 'panel22'
  },
  {
    title: 'Quais foram as mudanças que aconteceram entre o abastece-aí e o programa Km de Vantagens?',
    Description: () => (
      <div>
        <p>
          Agora o abastece-aí e Km de Vantagens são KMV, um programa de fidelidade completo que permite que seu cliente:
        </p>
        <p>
          - Acumule e troque Pontos KMV por benefícios em nossos mais de 70 parceiros
        </p>
        <p>
          - Efetue pagamentos e ganhe até 5% cashback ao utilizar o app para abastecer nos postos Ipiranga
        </p>
        <p>
          - Tenha uma experiência simples e unificada, com acesso a todos os benefícios que ambos os aplicativos oferecem!
        </p>
      </div>
    ),
    panel: 'panel23'
  },
  {
    title: 'O que é o programa KMV?',
    Description: () => (
      <div>
        <p>
          O KMV é o programa de fidelidade dos postos Ipiranga. Com ele, a cada R$ 1,00 gasto em produtos ou serviços na rede credenciada de postos Ipiranga ou nos sites de parceiros, seu cliente acumula pontos KMV que te dão acesso a cashback, promoções e benefícios exclusivos em mais de 70 parceiros.
        </p>
      </div>
    ),
    panel: 'panel24'
  },
  {
    title: 'Como meu cliente pode baixar o app KMV?',
    Description: () => (
      <div>
        <p>
          Para baixar o app, oriente seu cliente a acessar a loja de aplicativos do celular (Google Play Store para dispositivos Android ou App Store para dispositivos iOS), busque pelo app do KMV e realize o download e a instalação no celular. Pronto!
        </p>
      </div>
    ),
    panel: 'panel25'
  },
  {
    title: 'Quem já tem o app abastece-aí instalado, muda alguma coisa?',
    Description: () => (
      <div>
        <p>
          Todos os benefícios continuam! Você vai observar que o nome e o visual do app mudaram, mas vamos continuar oferecendo todos produtos e vantagens para nossos clientes.
        </p>
        <p>
          O sistema de acúmulo de pontos e resgate de cashback nos abastecimentos nos postos Ipiranga continuam o mesmo, porém agora em uma experiência mais fluida para o cliente final!
        </p>
      </div>
    ),
    panel: 'panel26'
  },
  {
    title: 'O cliente corre o risco de perder o saldo de Km de Vantagens com essa mudança?',
    Description: () => (
      <div>
        <p>
          Não. O abastece-aí agora é KMV e o saldo acumulado se chama Pontos KMV, continuando válido para trocar por cashback, descontos e benefícios. Lembrando apenas que os Pontos KMV possuem validade, mas eles não sofrerão impacto com a mudança!
        </p>
      </div>
    ),
    panel: 'panel27'
  },
  {
    title: 'O cliente vai perder o cashback disponível no app?',
    Description: () => (
      <div>
        <p>
          Não. A mudança de visual não influencia no saldo de cashback e seu cliente continuará resgatando os benefícios conforme nosso fluxo atual! Não se preocupe, caso haja qualquer mudança, nós te avisaremos!
        </p>
      </div>
    ),
    panel: 'panel28'
  },
  {
    title: 'Como o cliente pode acumular pontos pelo novo app do KMV?',
    Description: () => (
      <div>
        <p>
          O sistema de acúmulo e resgate de pontos continuam o mesmo, portanto basta o cliente abastecer nos postos Ipiranga participantes, fazer compras nas lojas AmPm e em lojas parceiras, ou até mesmo recarregar seu o celular no app para ganhar Pontos KMV.
        </p>
      </div>
    ),
    panel: 'panel29'
  },
  {
    title: 'O cliente pode usar o cashback adquirido no KMV em qualquer posto de combustível?',
    Description: () => (
      <div>
        <p>
          Não. O cashback adquirido no KMV volta para o cliente através da carteira digital e fica disponível como saldo para ser usado nos postos da bandeira Ipiranga e que participam do programa de fidelidade.
        </p>
        <p>
          Seu cliente poderá utilizar o saldo nos futuros abastecimentos em postos Ipiranga ao pagar pelo app, conforme o fluxo atual!
        </p>
      </div>
    ),
    panel: 'panel30'
  }
]
