import React, { useState } from 'react'
import ReactGA from 'react-ga'
import { Rating } from '@material-ui/lab'
import { Col, Row } from 'reactstrap'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import { toast } from 'react-toastify'
import Box from '@material-ui/core/Box'
import { feedbackSave } from '../api/feedback'
import { useSelector } from 'react-redux'
import { Button, Label, Form, Input, FormGroup } from 'reactstrap'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles({
  rating: {
    marginTop: '1rem',
    '& label': {
      margin: 0
    }
  },
  loading: {
    color: '#fff'
  }
})

const RatingModal = (props) => {
  const { handleClose } = props
  const classes = useStyles()
  const [voted, setVoted] = useState(false)
  const [voteSuccess, setVoteSuccess] = useState(false)
  const [valueStar, setValueStar] = useState(0)
  const [valueComments, setValueComments] = useState('')
  const [labelText, setLabelText] = useState('')
  const [loading, setLoading] = useState(false)
  const LabelNegative = 'Como podemos melhorar?'
  const LabelPositive = 'Possui alguma sugestão?'

  const {
    userState: { authUser }
  } = useSelector((state) => state)

  const forwardForm = () => {
    window.open('https://forms.office.com/r/0XWKFAtURf', '_blank')
    handleClose()
  }
  const formSubmit = async () => {
    setLoading(true)
    if (valueStar) {
      const data = {
        email: authUser?.data?.Email,
        score: valueStar,
        comment: valueComments
      }

      const submitResponse = await feedbackSave(data)

      if (submitResponse) {
        setLoading(false)
        toast.success('Agradecemos sua avaliação! Sua opinião é muito importante para nós.', {
          toastId: 'feedbackSave',
          autoClose: 2000
        })
        ReactGA.event({
          category: 'user_behavior',
          action: authUser?.data?.Name,
          label: data.comment,
          value: data.score
        })
      }

      setVoteSuccess(true)
    } else {
      toast.info('Por favor, classifique sua experiência no Portal.', {
        toastId: 'feedbackSave',
        autoClose: 2000
      })
    }
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      {!voteSuccess && (
        <>
          <Row className="modal-header-stars">
            <Col className="modal-header-title">O que você está achando do novo portal?</Col>
          </Row>
          <Row className="modal-header-stars">
            <Col className="modal-header-sub-title">
              Classifique como está sendo sua experiência
            </Col>
          </Row>
        </>
      )}

      <Box
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={3}
        borderColor="transparent"
      >
        {voteSuccess && (
          <div className="modal-form">
            <Row>
              <Col>
                <p className="modal-form-header">Quer ajudar o Portal a melhorar ainda mais?</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="modal-form-body">
                  Basta responder o formulário abaixo, leva apenas 3 minutos
                </div>
              </Col>
            </Row>
          </div>
        )}
        {!voteSuccess && (
          <Rating
            size="large"
            className={classes.rating}
            name="rating-portal"
            defaultValue={0}
            value={valueStar}
            onChange={(event, newValue) => {
              setValueStar(newValue)
              setVoted(true)
              setLabelText(newValue > 4 ? LabelPositive : LabelNegative)
            }}
            precision={1}
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
          />
        )}
      </Box>
      {!voteSuccess && voted && (
        <Box width="100%">
          <Form>
            <FormGroup>
              <Label for="volteArea" className="label-text-area">
                {labelText}
              </Label>
              <Input
                type="textarea"
                name="text"
                id="volteArea"
                value={valueComments}
                onChange={(e) => setValueComments(e.target.value)}
                rows={5}
                placeholder="Escreva sua sugestão aqui"
              />
            </FormGroup>
          </Form>
        </Box>
      )}
      {!voteSuccess && voted && (
        <Button
          disabled={loading || !valueStar}
          color="primary"
          className="px-3 py-2 w-100"
          onClick={formSubmit}
        >
          {loading ? <CircularProgress size={18} className={classes.loading} /> : 'Enviar sugestão'}
        </Button>
      )}
      {voteSuccess && (
        <Button color="primary" className="px-3 py-2 w-100" onClick={forwardForm}>
          Responder Formulário
        </Button>
      )}
    </Box>
  )
}

export default RatingModal
