import React, { useState } from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
import { fetchDownloadTerm } from '../lib/utils'
import Modal from './modal'
import statusPaymentMethod from '../model/enum/statusPaymentMethod'

/**
 *
 * Informações gerais
 * - Content orientado por linhas
 */

const BoxGeneral = ({ boxTitle, data }) => (
  <div className="box-info">
    <h3>{boxTitle}</h3>
    <div className="box-info--list">
      {data &&
        data.map((item) => (
          <div className="box-info--list--item" key={Math.random()}>
            {item.map(({ title, info, alert }) => (
              <div className="box-info--list--item--fragment" key={title}>
                <h4>{title}</h4>
                <p>{info}</p>
                {alert && <small>{alert}</small>}
              </div>
            ))}
          </div>
        ))}
    </div>
  </div>
)

BoxGeneral.propTypes = {
  boxTitle: PropTypes.string,
  data: PropTypes.array
}

/**
 *
 * Box formas de pagamento, comissões e liquidações
 * - Content orientado por colunas
 */

const BoxDetails = ({ data: { boxTitle, header, items } }) => {

  return (
    <div className="box-info">
      <h3>{boxTitle}</h3>
      <div className="box-info--table">
        <div className="box-info--table--header">
          {header.map((title) => (
            <div className="box-info--table--row" key={title}>
              <h4>{title}</h4>
            </div>
          ))}
        </div>
        <div className="box-info--table--body">
          {items.map(({ type, commissions, saleOff }) => (
            <div className="box-info--table--column" key={Math.random()}>
              <div className="box-info--table--row">
                <p>
                  {statusPaymentMethod.getValue(type)}{' '}
                </p>
              </div>
              <div className="box-info--table--row">
                <p>{commissions}</p>
              </div>
              <div className="box-info--table--row">
                <p>{saleOff}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

BoxDetails.propTypes = {
  boxTitle: PropTypes.string,
  data: PropTypes.object
}

/**
 *
 * Checkbox termos
 *
 */

const CheckElement = ({ label, name, link, action }) => {
  const [active, updateActive] = useState(true)

  function handleUpdateStatus(status) {
    updateActive(!status)
    action({ [name]: !active })
  }

  return (
    <div className="checkElement">
      <label htmlFor={name}>
        <input
          type="checkbox"
          id={name}
          onChange={() => handleUpdateStatus(active)}
          checked={active}
        />
        {label}
      </label>
      {link && (
        <a
          href=":void()"
          rel="noreferrer"
          className="btn-download"
          onClick={(e) => fetchDownloadTerm(e)}
        >
          <img src="/assets/icons/download-dark-icon.svg" alt="Icone para download" />
        </a>
      )}
    </div>
  )
}

CheckElement.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired
}

const ModalTerms = ({ action, isOpen }) => (
  <Modal
    isOpen={isOpen}
    header={() => <span>Contate a Central de Ajuda</span>}
    body={() => (
      <div className="modalBodyTerms">
        <div>Houve um erro no processo de criação da sua conta.</div>
        <div>
          Entre em contato com a nossa Central de Ajuda <b>3003-3470</b>
        </div>
      </div>
    )}
    toggle={() => action()}
    footer={({ toggle }) => (
      <Button color="primary" onClick={toggle} className="bt-modal">
        Entendi
      </Button>
    )}
  />
)

ModalTerms.propTypes = {
  action: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
}

export { BoxGeneral, BoxDetails, CheckElement, ModalTerms }
