import { decode as atob } from 'base-64'
import { downloadDocument, downloadDocumentPerformance } from '../actions/terms'
import Config from '../config/environments'

export function minMaxRange(numberValue, min, max) {
  if (numberValue > max) {
    return max
  }
  if (numberValue < min) {
    return min
  }
  return numberValue
}
export function removeColumns(isIPP, table) {
  if (isIPP)
    table.column = table.column.filter(
      (col) => col.accessor !== 'paymentMethod' && col.Header !== 'Cashback'
    )
  if (!isIPP)
    table.column = table.column.filter(
      (col) => col.accessor !== 'creditReceived' && col.accessor !== 'credit_receivable'
    )
  return table
}

export function substrOrderCodeOrId(e) {
  const initiString = !e.target.value.toLocaleUpperCase().indexOf('P')
  e.target.value = e.target.value.substr(0, initiString ? 16 : 40)
}

export function converterStoreUuidArrayTOString(stores) {
  return stores.data ? stores.data.reduce((uuids, branch) => `${uuids + branch.Uuid},`, '') : ''
}

export const fetchDownloadTerm = async (event) => {
  await fetchDownload(event, Config.USER.DOCUMENT_TERM_NAME, Config.USER.PATH_TERM_DOCUMENT)
}


export const fetchDownloadGuide = async (event) => {
  await fetchDownload(event, Config.USER.DOCUMENT_USER_GUIDE, Config.USER.PATH_USER_GUIDE_DOCUMENT)
}

export const fetchDownloadPerformance = async (event, document, type, date) => {
  await fetchDownloadPDF(event, Config.USER.DOCUMENT_PERFORMANCE, Config.USER.PERFORMANCE, document, type, date)
}

export const fetchDownload = async (event, download, path) => {
  event.preventDefault()
  const result = await downloadDocument(download, path)
  if (result && result.data && result.data.fileBase64) {
    const byteCharacters = atob(result.data.fileBase64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = download
    a.click()
  }
}
export const fetchDownloadPDF = async (event, download, path, document, type, date) => {
  event.preventDefault()
  const result = await downloadDocumentPerformance(path, document, type, date)
  if (result && result.data) {
    window.open(result.config.url);
  }
}
