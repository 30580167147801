import Enum from 'enum'

const Status = new Enum({
  BALANCE: 'Saldo',
  CREDIT_CARD: 'Cartão de Crédito',
  CASH: 'Dinheiro',
  TICKET_MEAL: 'Ticket Refeição',
  TICKET_FOOD: 'Ticket Alimentação',
  TICKET_CULTURE: 'Ticket Cultura',
  CANCELLED: 'Estorno',
  COUPON: 'Cupom',
  DEBIT_CARD: 'Débito',
  DIGITAL_WALLET: 'Carteira Digital',
  QRCODE_PIX: 'QRCode PIX'
})

const Badge = new Enum({
  BALANCE: 'Autorizado',
  CREDIT_CARD: 'Cancelado',
  CASH: 'Cancelado',
  TICKET_MEAL: 'Cancelado',
  TICKET_FOOD: 'Cancelado',
  TICKET_CULTURE: 'Cancelado',
  COUPON: 'Cupom',
  DEBIT_CARD: 'Cancelado',
  DIGITAL_WALLET: 'Cancelado',
  QRCODE_PIX: 'Cancelado'
})

export default {
  get: () => Status.enums,

  getName: (value) => {
    try {
      if (!value) return null

      const { key } = Status.get(value)

      return key
    } catch (error) {
      throw new Error(`StatusPaymentMethodEnum - GetName : ${error}`)
    }
  },

  getValue: (name) => {
    try {
      if (!name) return null
      const { value } = Status.get(name)
      return value
    } catch (error) {
      throw new Error(`StatusTransactionEnum - GetValue : ${error}`)
    }
  },

  getBadge: (name) => {
    try {
      if (!name) return null

      const { value } = Badge.get(name)

      return value
    } catch (error) {
      throw new Error(`StatusTransactionEnum - GetBadge : ${error}`)
    }
  }
}
