export const Breadcrumb = {
  steps: [
    {
      name: 'Dados gerais',
      icon: 'fa-id-card',
      route: '/lojas/cadastro/geral',
      statusOk: false
    },
    {
      name: 'Contatos',
      icon: 'fa-map',
      route: '/lojas/cadastro/endereco',
      statusOk: false
    },
    {
      name: 'Imagens',
      icon: 'fa-image',
      route: '/lojas/cadastro/imagens',
      statusOk: false
    },
    {
      name: 'Financeiro',
      icon: 'fa-money',
      route: '/lojas/cadastro/financeiro',
      statusOk: false
    },
    {
      name: 'Resumo',
      icon: 'fa-list-alt',
      route: '/lojas/cadastro/resumo',
      statusOk: false
    }
  ],
  currentStep: 0
}

export const StoreGeneralModel = {
  storeOwner: {
    firstName: '',
    lastName: '',
    document: '',
    birthDate: ''
  },
  name: '',
  fantasyName: '',
  document: '',
  email: '',
  type: '',
  category: '',
  headStoreId: null,
  headOffice: true,
  redirectUrl: '',
  hasRedirectUrl: false,
  integrator: false,
  about: '',
  rules: ''
}

export const StoreAddressModel = {
  address: {
    zipCode: '',
    street: '',
    streetNumber: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    country: 'BR',
    latitude: '',
    longitude: ''
  },
  shopping: null,
  phones: [
    {
      countryCode: '55',
      areaCode: '',
      number: '',
      whatsapp: false
    }
  ]
}

export const StoreImagesModel = {
  photo: '',
  background: '',
  listImage: ''
}

export const StoreFinancialModel = {
  bank: {
    uuid: ''
  },
  agency: '',
  agencyCheckNumber: '',
  accountNumber: '',
  accountCheckNumber: '',
  storeCashback: {
    uuid: null,
    url: null,
    expirationTime: null,
    dailyCashbacks: [
      {
        uuid: null,
        day: 'MONDAY',
        percentage: 0
      },
      {
        uuid: null,
        day: 'TUESDAY',
        percentage: 0
      },
      {
        uuid: null,
        day: 'WEDNESDAY',
        percentage: 0
      },
      {
        uuid: null,
        day: 'THURSDAY',
        percentage: 0
      },
      {
        uuid: null,
        day: 'FRIDAY',
        percentage: 0
      },
      {
        uuid: null,
        day: 'SATURDAY',
        percentage: 0
      },
      {
        uuid: null,
        day: 'SUNDAY',
        percentage: 0
      }
    ]
  },
  paymentMethods: [
    {
      method: {
        uuid: '',
        name: '',
        description: ''
      },
      fixedCommission: 0,
      active: false,
      transfer: false,
      dailyTransfer: false,
      waitDays: 1,
      percentageCommission: 0,
      onlendingDay: '',
      installmentCount: 1,
      minimumInstallment: 0,
      installments: [
        {
          parcel: '1',
          interest: '0'
        }
      ]
    }
  ]
}

export default {
  Redirect: false,
  Breadcrumb,
  ...StoreGeneralModel,
  ...StoreAddressModel,
  ...StoreImagesModel,
  ...StoreFinancialModel,

  active: false,
  emailBooking: '',
  booking: false,
  brand: null,
  storeIntegrator: null,
  merchantId: ''
}
