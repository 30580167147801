import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Form,
  Media
} from 'reactstrap'
import PropTypes from 'prop-types'
import { forgotPasswordDataUser } from '../../actions/user'
import { modelToForgotPasswordViewModel } from '../../mapper/user'
import FORGOT_PASSWORD from '../../const/forgot-password'
import IconMail from '../../components/icons/mail'
import LoadingComponent from '../../components/loading'
import { validateEmail } from '../../lib/mask'
import ReactGA from 'react-ga';

class ForgotPassword extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = { Loading: false, Sent: false, errorLogin: '' }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGoToLogin = this.handleGoToLogin.bind(this)
  }

  componentDidMount() {
    ReactGA.event({
      category: 'Esqueci minha senha',
      action: 'Esqueci minha senha',
    });
  }

  handleSubmit(event) {
    event.preventDefault()
    const { Email } = this.state
    const trimEmail = Email.trim()

    if (!validateEmail(trimEmail)) {
      this.setState({ errorLogin: FORGOT_PASSWORD.ERROR_FORMAT })
    }
    if (validateEmail(trimEmail)) {
      const { props } = this
      const state = modelToForgotPasswordViewModel(this.state)
      props.forgotPasswordDataUser(state)
      this.setState({ Loading: true })
      setTimeout(
        () =>
          this.setState({
            Loading: false
          }),
        4000
      )
    }
  }

  handleGoToLogin() {
    const { history } = this.props
    history.push({
      pathname: '/login',
      state: null
    })
  }

  handleChangeEmail(email, error) {
    this.setState({ Email: email.trim(), errorLogin: error })
  }

  render() {
    const { props } = this
    const { Email, Loading, Sent, errorLogin } = this.state

    if (props.forgotPassword.data && !props.forgotPassword.data.error && Loading) {
      this.setState({ Sent: true, Loading: false })
    }
    if (props.forgotPassword.data && props.forgotPassword.data.error && Loading) {
      this.setState({
        Loading: false,
        errorLogin: props.forgotPassword.data.error
      })
    }

    return (
      <div className="app back-logo flex-row align-items-center">
        <Container>
          <Form onSubmit={this.handleSubmit}>
            <Row className="justify-content-center">
              <Col md="6">
                <button
                  type="button"
                  className="container-logo mx-auto d-flex justify-content-center"
                  onClick={this.handleGoToLogin}
                >
                  <Media
                    className="logo d-block mb-4"
                    object
                    src="/assets/imgs/logo_transparent.png"
                    alt="Logo"
                    style={{ width: '90px', height: '90px' }}
                  />
                  <p className="title h4 mb-4 text-white d-flex align-items-center flex-wrap">
                    <span className="w-100">{FORGOT_PASSWORD.LOGO_TITLE}</span>
                    <span>{FORGOT_PASSWORD.LOGO_SUB_TITLE}</span>
                  </p>
                </button>
                <CardGroup>
                  <Card className="px-3 pt-3 pt-2 box-login">
                    <CardBody>
                      {Loading && <LoadingComponent />}

                      <Col md="12" className="p-0">
                        <p className="title mb-3 text-center h4 text-primary">
                          {Sent ? 'E-mail enviado' : FORGOT_PASSWORD.TITLE}
                        </p>
                        {Sent ? (
                          <>
                            <p className="mt-4 mb-3 text-primary">
                              Instruções para recuperar a senha enviadas para o e-mail{' '}
                              <strong>{Email}</strong>.
                            </p>
                            <p className="mt-4 mb-3 text-primary">
                              Caso o e-mail não chegue em instantes, verifique se o e-mail foi
                              digitado corretamente e não se esqueça de olhar sua caixa de spam.
                            </p>
                            <p className="mt-0 mb-4 text-primary">
                              Precisando, entre em contato com a central pelos telefones 3003-3470
                              ou 0800 707-6656.
                              {/* <button onClick={this.handleSubmit} className="link" type="button">reenvie.</button> */}
                            </p>
                          </>
                        ) : (
                          <p className="mt-4 mb-1 text-primary">{FORGOT_PASSWORD.LOGIN_TEXT}</p>
                        )}
                      </Col>

                      {!Sent && (
                        <>
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText
                                className={`pt-2 pl-3 pr-0 ${errorLogin ? 'border-danger' : ''}`}
                              >
                                <IconMail color="var(--color-blue-gray)" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className={`px-3 ${Email && ' full'} ${
                                errorLogin ? 'border-danger' : ''
                              }`}
                              type="text"
                              placeholder=""
                              required="required"
                              id="Email"
                              name="Email"
                              value={Email}
                              onChange={(e) => this.handleChangeEmail(e.target.value, errorLogin)}
                            />
                            <span className="placeholder">E-mail</span>
                          </InputGroup>

                          <Row>
                            <Col xs="12">
                              <Button disabled={!Email} className="px-4 py-3 w-100">
                                {FORGOT_PASSWORD.LOGIN_BUTTON}
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    )
  }
}

ForgotPassword.propTypes = {
  forgotPasswordDataUser: PropTypes.func.isRequired,
  forgotPassword: PropTypes.shape({
    data: PropTypes.shape(PropTypes.object)
  }).isRequired,
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  history: PropTypes.shape({ goBack: PropTypes.func, push: PropTypes.func }).isRequired
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forgotPasswordDataUser
    },
    dispatch
  )

const mapStateToProps = (state) => ({
  forgotPassword: state.userState.forgotPassword
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
