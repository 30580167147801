import { axiosInstance as axios } from '../lib/axios'
import Config from '../config/environments'
import { localRemove } from '../lib/session'
import { DEFAULT_STORE, LIST_BRANCH, IS_IPP } from '../actionTypes/branch'
import { viewsModelToModels } from '../mapper/branch'
import { updateState } from '.'
import history from '../service/history'

export const getDataBranch = (document) => async (dispatch, getState) => {
  const base = {
    type: LIST_BRANCH,
    payload: {},
    state: true
  }

  let url = Config.STORE.BRANCH

  const {
    userState: { authUser }
  } = getState()

  if (authUser.readOnly || document) {
    url = `${Config.STORE.BACKOFFICE_BRANCHES}/${document}`
  }

  axios
    .get(url)
    .then((response) => {
      base.payload = updateState(response)
      base.payload.data = viewsModelToModels(base.payload.data)

      const isIPP = base.payload.data && base.payload.data[0].IPP ? true : false
      dispatch({
        type: IS_IPP,
        payload: { state: false, isIPP },
        state: true
      })

      if (!base.payload.data) {
        history.goBack()
      }
    })
    .catch((error) => {
      base.payload = error
    })
    .finally(() => {
      dispatch(base)
    })

  dispatch(base)
}

export const setDefaultStore = (data) => async (dispatch) => {
  dispatch({
    type: DEFAULT_STORE,
    payload: { state: false, data },
    state: true
  })

  if (!data) localRemove('STORES')
}
