import './middlewares/polyfill'
import ReactDOM from 'react-dom'
import React from 'react'
import { Provider } from 'react-redux'
import { ReactTableDefaults } from 'react-table-6'
import { unregister } from './middlewares/registerServiceWorker'
import createStore from './store/store'
import history from './service/history'
import unsupportedBrowser from './lib/unsupported-browser'
import { setSessionFlagByURLParam } from './lib/session-flag'
import TableDefaults from './config/table/table'
import 'moment/locale/pt-br'
import 'react-dates/initialize'


import 'react-table-6/react-table.css'
import 'flag-icon-css/css/flag-icon.min.css'
import 'font-awesome/css/font-awesome.min.css'
import './scss/style.css'
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import App from './app'


const store = createStore(window.__INITIAL_STATE__)

//const isProdURL = (window.location.hostname === 'parceirosabasteceai.com.br' || window.location.hostname === 'www.parceirosabasteceai.com.br');

setSessionFlagByURLParam()

// Temporary Work Siding [ BPE-2084 ]
/* const dontHaveTheFlagBPE2084 = !hasSessionFlag('PROD', 'BPE-2084');
if (isProdURL && dontHaveTheFlagBPE2084) history.push({ pathname: '/em-breve' }); */

if (unsupportedBrowser()) history.push({ pathname: '/nao-suportado' })

Object.assign(ReactTableDefaults, TableDefaults)



ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <App />
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('root')
)

unregister()
