export default (error, message) => {
  try {
    if (!message && !error) return null

    let err = error

    err = err.includes('unable.to.retrieve.balance') ? 'Falha ao recuperar saldo na WireCard' : err
    err = err.includes('TRANSFER_FUND_NOT_FOUND') ? 'Transação não encontrada' : err
    err = err.includes('USER_NOT_FOUND') ? 'Usuário não encontrado' : err
    err = err.includes('UNABLE_TO_RETRIEVE_BALANCE')
      ? 'Não foi possível consultar o saldo dessa loja'
      : err
    // err = err.includes('INTERNAL_ERROR') ? 'Erro interno inesperado' : err;
    // err = err.includes('ONLY_NUMBER') ? 'Somente números aceitos' : err;

    return err
  } catch (err) {
    return message
  }
}
