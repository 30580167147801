import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Form,
  Media
} from 'reactstrap'
import PropTypes from 'prop-types'
import { resetPasswordDataUser } from '../../actions/user'
import { modelToResetPasswordViewModel } from '../../mapper/user'
import RESET_PASSWORD from '../../const/reset-password'
import LoadingComponent from '../../components/loading'
import IconLock from '../../components/icons/lock'

class ResetPassword extends Component {
  constructor(props, context) {
    super(props, context)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGoToLogin = this.handleGoToLogin.bind(this)
    this.state = {
      Password: '',
      ConfirmPassword: '',
      Loading: false,
      Sent: false,
      errorReset: ''
    }
  }

  async handleSubmit(event) {
    event.preventDefault()
    const { Password, ConfirmPassword } = this.state
    const { props } = this
    const { uuid } = props.match.params
    await this.setState({ UserToken: uuid })
    if (Password.length < 6) {
      this.setState({ errorReset: RESET_PASSWORD.MINIMUM_VALUE })
    } else if (Password === ConfirmPassword) {
      const state = modelToResetPasswordViewModel(this.state)
      props.resetPasswordDataUser(state)
      this.setState({ Loading: true })
      setTimeout(
        () =>
          this.setState({
            Loading: false
          }),
        4000
      )
    } else {
      this.setState({ errorReset: RESET_PASSWORD.DIFFERENT_PASSWORD })
    }
  }

  handleGoToLogin() {
    const { history } = this.props
    history.push({
      pathname: '/login',
      state: null
    })
  }

  render() {
    const { props } = this
    const { Password, ConfirmPassword, Loading, Sent, errorReset } = this.state

    if (props.resetPassword.data && Loading) {
      this.setState({ Sent: true, Loading: false })
    }

    return (
      <div className="app back-logo flex-row align-items-center">
        <Container>
          <Form onSubmit={this.handleSubmit}>
            <Row className="justify-content-center">
              <Col md="6">
                <button
                  type="button"
                  className="container-logo mx-auto d-flex justify-content-center"
                  onClick={this.handleGoToLogin}
                >
                  <Media
                    className="logo d-block mb-4"
                    object
                    src="/assets/imgs/logo_transparent.png"
                    alt="Logo"
                  />
                  <p className="title h4 mb-4 text-white d-flex align-items-center flex-wrap">
                    <span className="w-100">{RESET_PASSWORD.LOGO_TITLE}</span>
                    <span>{RESET_PASSWORD.LOGO_SUB_TITLE}</span>
                  </p>
                </button>
                <CardGroup>
                  <Card className="px-3 pt-3 pt-2 box-login">
                    <CardBody>
                      {Loading && <LoadingComponent />}

                      <Col md="12" className="p-0">
                        <p className="title mb-3 text-center h4 text-primary">
                          {Sent ? RESET_PASSWORD.SENT_TITLE : RESET_PASSWORD.TITLE}
                        </p>
                      </Col>
                      {Sent ? (
                        <p className="mt-4 mb-3 text-primary">{RESET_PASSWORD.SENT_TEXT}</p>
                      ) : (
                        <>
                          <InputGroup className="mt-4 mb-0">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText
                                className={`pl-3 pr-0 ${errorReset ? 'border-danger' : ''}`}
                              >
                                <IconLock color="var(--color-blue-gray)" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className={`px-3 ${Password && ' full'} ${
                                errorReset ? 'border-danger' : ''
                              }`}
                              type="password"
                              placeholder=""
                              required="required"
                              id="Password"
                              name="Password"
                              value={Password}
                              onChange={(e) =>
                                this.setState({ Password: e.target.value, errorReset: '' })
                              }
                            />
                            <span className="placeholder">Digite sua senha</span>
                            {errorReset && <i className="error-icon">!</i>}
                          </InputGroup>
                          <p className="error-text text-danger mb-1">{errorReset}</p>

                          <InputGroup className="mt-4 mb-0">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText
                                className={`pl-3 pr-0 ${errorReset ? 'border-danger' : ''}`}
                              >
                                <IconLock color="var(--color-blue-gray)" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className={`px-3 ${ConfirmPassword && ' full'} ${
                                errorReset ? 'border-danger' : ''
                              }`}
                              type="password"
                              placeholder=""
                              required="required"
                              id="ConfirmPassword"
                              name="ConfirmPassword"
                              value={ConfirmPassword}
                              onChange={(e) =>
                                this.setState({ ConfirmPassword: e.target.value, errorReset: '' })
                              }
                            />
                            <span className="placeholder">Confirme sua senha</span>
                            {errorReset && <i className="error-icon">!</i>}
                          </InputGroup>
                          <p className="error-text text-danger mb-1">{errorReset}</p>
                        </>
                      )}
                      <Row>
                        <Col xs="12">
                          {Sent ? (
                            <Button
                              onClick={this.handleGoToLogin}
                              type="button"
                              className="px-4 py-3 w-100"
                            >
                              {RESET_PASSWORD.REDIRECT_BUTTON}
                            </Button>
                          ) : (
                            <Button
                              disabled={!Password || !ConfirmPassword}
                              className="px-4 py-3 w-100"
                            >
                              {RESET_PASSWORD.SEND_BUTTON}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    )
  }
}

ResetPassword.propTypes = {
  resetPasswordDataUser: PropTypes.func.isRequired,
  resetPassword: PropTypes.shape({
    data: PropTypes.shape(PropTypes.object)
  }).isRequired,
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  history: PropTypes.shape({ goBack: PropTypes.func, push: PropTypes.func }).isRequired
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPasswordDataUser
    },
    dispatch
  )

const mapStateToProps = (state) => ({
  resetPassword: state.userState.resetPassword
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
