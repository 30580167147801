import { axiosInstance as axios, axiosLogin } from '../lib/axios'
import Config from '../config/environments'
import { localSet } from '../lib/session'
import { viewModelToModel, viewsModelToModels, viewModelTermAcceptedToModel } from '../mapper/user'
import { getPermission } from '../service/user'
import {
  AUTH_USER,
  LIST_USER,
  POST_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_TERM_ACCEPTED,
  POST_TERM_ACCEPTED,
  RESET_USER_STATE
} from '../actionTypes/user'
import { updateState } from '.'

export const getDataUser = () => async (dispatch) => {
  const base = {
    type: LIST_USER,
    payload: {}
  }

  base.state = true

  const url = `${Config.USER.URL}?page=1&quantity=100`

  axios
    .get(url)
    .then((response) => {
      base.payload = updateState(response)
      base.payload.data = viewsModelToModels(base.payload.data)
    })
    .catch((error) => {
      base.payload = error
    })
    .finally(() => {
      dispatch(base)
    })

  dispatch(base)
}

export const authDataUser = (data) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    state: true,
    payload: {}
  }

  const url = Config.USER.AUTH

  axiosLogin
    .post(url, data)
    .then((response) => {
      base.payload = updateState(response)
      base.payload.data = viewModelToModel(base.payload.data)
      base.payload.readOnly = getPermission(base.payload?.data?.Type).readOnly

      localSet('AUTH_TOKEN', base.payload?.data, Config.EXPIRATION_AUTH)
      localSet('IS_LOGGED', 'true', Config.EXPIRATION_AUTH)
    })
    .catch((error) => {
      base.payload = error
    })
    .finally(() => {
      dispatch(base)
    })
}

export const setStatusAuth = (data) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    payload: { data, state: false }
  }

  if (data?.Uuid) {
    base.payload.readOnly = getPermission(data.Type).readOnly

    await getUserType(data.Uuid)
      .then((response) => {
        const { data } = response
        base.payload.readOnly = getPermission(data?.type).readOnly
        dispatch(base)
      })
      .catch(() => {
        return false
      })
  }

  dispatch(base)
}

export const postDataUser = (data) => async (dispatch) => {
  const base = {
    type: POST_USER,
    payload: {}
  }

  base.state = true

  const url = Config.USER.URL

  axios
    .post(url, data)
    .then((response) => {
      base.payload = updateState(response)
    })
    .catch((error) => {
      base.payload = error
    })
    .finally(() => {
      dispatch(base)
    })

  dispatch(base)
}

export const putDataUser = (data) => async (dispatch) => {
  const base = {
    type: POST_USER,
    payload: {}
  }

  base.state = true

  const url = `${Config.USER.URL}/${data.uuid}`

  axios
    .put(url, data)
    .then((response) => {
      base.payload = updateState(response)
    })
    .catch((error) => {
      base.payload = error
    })
    .finally(() => {
      dispatch(base)
    })

  dispatch(base)
}

export const forgotPasswordDataUser = (data) => async (dispatch) => {
  const base = {
    type: FORGOT_PASSWORD,
    state: true,
    payload: {}
  }

  const url = `${Config.USER.FORGOT_PASSWORD}/${data.email}`

  axios
    .put(url, data)
    .then((response) => {
      base.payload = updateState(response)
    })
    .catch((error) => {
      base.payload = error
    })
    .finally(() => {
      dispatch(base)
    })

  dispatch(base)
}

export const resetPasswordDataUser = (data) => async (dispatch) => {
  const base = {
    type: RESET_PASSWORD,
    state: true,
    payload: {}
  }

  const url = `${Config.USER.RESET_PASSWORD}/${data.token}`

  axios
    .post(url, data)
    .then((response) => {
      base.payload = updateState(response)
    })
    .catch((error) => {
      base.payload = error
    })
    .finally(() => {
      dispatch(base)
    })

  dispatch(base)
}

export const getUserType = (Uuid) => {
  const url = `${Config.USER.DEFAULT}/${Uuid}/type`

  return axios.get(url)
}

export const getTermAccepted = (data) => async (dispatch) => {
  const base = {
    type: GET_TERM_ACCEPTED,
    state: true,
    payload: {}
  }

  const url = `${Config.USER.STORE}/${data.Email}`

  axios
    .get(url)
    .then((response) => {
      base.payload = updateState(response)
      base.payload.data = viewModelTermAcceptedToModel(base.payload.data)
      localSet('TERM', base.payload.data, Config.EXPIRATION_AUTH)
    })
    .catch((error) => {
      base.payload = error
    })
    .finally(() => {
      dispatch(base)
    })

  dispatch(base)
}

export const resetUserState = () => async (dispatch) => {
  const base = {
    type: RESET_USER_STATE
  }

  dispatch(base)
}

export const updateTermAccepted = (data) => async (dispatch) => {
  const base = {
    type: POST_TERM_ACCEPTED,
    state: true,
    payload: {}
  }

  base.state = true

  const url = `${Config.USER.STORE_MERCHANT_ACCOUNT}/${data.Uuid}/approval`

  axios
    .post(url, null)
    .then((response) => {
      if (response.status === 204) {
        response.data = {
          success: true
        }
      }
      base.payload = updateState(response)
    })
    .catch((error) => {
      base.payload = error
    })
    .finally(() => {
      dispatch(base)
    })

  dispatch(base)
}

export const sendTermAccepted = async (id) => {
  try {
    const url = `${Config.USER.STORE_MERCHANT_ACCOUNT}/${id}/approval`
    const response = await axios.post(url, null)

    if (response.status === 204) {
      return true
    }
    return false
  } catch (e) {
    return null
  }
}
