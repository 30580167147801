import React from 'react'
import { Modal as ModalContainer, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

function ModalPropContent({ header: Header, body: Body, footer: Footer, toggle, hideCloseButton }) {
  return (
    <>
      <ModalHeader>
        {Header && <Header toggle={toggle} />}
        {!hideCloseButton && (
          <Button className="close-btn" onClick={toggle}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
      </ModalHeader>

      <ModalBody>{Body && <Body toggle={toggle} />}</ModalBody>

      <ModalFooter>{Footer && <Footer toggle={toggle} />}</ModalFooter>
    </>
  )
}

function Modal(props) {
  const { isOpen, toggle, children, className, centered, backdrop } = props
  return (
    <ModalContainer
      className={`component-modal ${className}`}
      isOpen={isOpen}
      toggle={toggle}
      centered={centered}
      backdrop={backdrop}
    >
      {children || ModalPropContent(props)}
    </ModalContainer>
  )
}

Modal.defaultProps = {
  backdrop: true,
  hideCloseButton: false
}

export default Modal
