import Model from '../model/user'
import { DocumentFormat } from '../lib/mask'
import moment from 'moment'
export const viewModelToModel = (view) => {
  try {
    if (!view) return null
    if (view.error) return view

    const model = Object.assign({}, Model)

    model.ResponseStatus = view.responseStatus
    model.Uuid = view.uuid
    model.Email = view.email
    model.Name = view.name
    model.Type = view.type
    model.Password = view.password
    model.Document = view.document ? view.document.replace(/\D/g, '') : ''
    model.Status = view.status
    model.Authorized = view.authorized
    model.LoginCount = view.count
    if (view.lastFeedback) model.LastFeedBack = new moment(view.lastFeedback)
    model.Groups = []
    model.termAccepted = view.termAccepted

    if (view.groups && view.groups.length > 0) {
      view.groups.forEach((group) => {
        model.Groups.push({ Id: group.id })
      })
    }

    return model
  } catch (error) {
    throw new Error(`UserMapper - viewModelToModel = ${error}`)
  }
}

export const viewsModelToModels = (models) => {
  try {
    if (!models) return null

    const views = []

    models.users.forEach((model) => {
      const view = viewModelToModel(model)

      views.push(view)
    })

    return views
  } catch (error) {
    throw new Error(`UserMapper - viewsModelToModels = ${error}`)
  }
}

export const modelToViewModel = (model) => {
  try {
    if (!model) return null

    const view = {}
    const groups = []

    model.Checkboxes.forEach((obj) => {
      if (obj.Checked) {
        groups.push({ id: obj.Id })
      }
    })

    view.responseStatus = model.ResponseStatus
    view.uuid = model.Uuid
    view.email = model.Email
    view.name = model.Name
    view.type = model.Type
    view.password = model.Password
    view.document = model.Document ? model.Document.replace(/\D/g, '') : ''
    view.status = model.Status
    view.groups = groups

    return view
  } catch (error) {
    throw new Error(`UserMapper - ModelToViewModel = ${error}`)
  }
}

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null

    const views = []

    models.forEach(async (model) => {
      const view = modelToViewModel(model)

      views.push(view)
    })

    return views
  } catch (error) {
    throw new Error(`UserMapper - ModelsToViewsModel = ${error}`)
  }
}

export const viewModelToState = (view) => {
  try {
    if (!view) return null

    const model = Object.assign({}, Model)

    model.ResponseStatus = view.ResponseStatus
    model.Uuid = view.Uuid
    model.Email = view.Email
    model.Name = view.Name
    model.Type = view.Type
    model.Password = view.Password || ''
    model.Document = DocumentFormat(view.Document)
    model.Status = view.Status
    model.Groups = view.Groups

    return model
  } catch (error) {
    throw new Error(`UserMapper - viewModelToState = ${error}`)
  }
}

export const modelToAuthViewModel = (model, token) => {
  try {
    if (!model) return null

    const view = {}

    view.email = model.Email
    view.password = model.Password
    view.reCaptcha = token

    return view
  } catch (error) {
    throw new Error(`UserMapper - modelToAuthViewModel = ${error}`)
  }
}

export const modelToTermViewModel = (model) => {
  try {
    if (!model) return null

    const view = {}

    view.userUuid = model.Uuid

    return view
  } catch (error) {
    throw new Error(`UserMapper - modelToTermViewModel = ${error}`)
  }
}

export const groupToCheckboxViewModel = (model, groups) => {
  try {
    if (!model) return null

    const view = {}

    view.Id = model.Id
    view.Name = model.Name
    view.Description = model.Description
    view.Checked = groups.some((g) => g.Id === model.Id)

    return view
  } catch (error) {
    throw new Error(`UserMapper - groupToCheckboxViewModel = ${error}`)
  }
}

export const groupsToCheckboxViewModel = (models, groups) => {
  try {
    if (!models) return null

    const views = []

    models.forEach((model) => {
      const view = groupToCheckboxViewModel(model, groups)

      views.push(view)
    })

    return views
  } catch (error) {
    throw new Error(`UserMapper - groupsToCheckboxViewModel = ${error}`)
  }
}

export const modelToForgotPasswordViewModel = (model) => {
  try {
    if (!model) return null

    const view = {}

    view.email = model.Email

    return view
  } catch (error) {
    throw new Error(`UserMapper - modelToAuthViewModel = ${error}`)
  }
}

export const modelToResetPasswordViewModel = (model) => {
  try {
    if (!model) return null

    const view = {}

    view.password = model.Password
    view.token = model.UserToken

    return view
  } catch (error) {
    throw new Error(`UserMapper - modelToAuthViewModel = ${error}`)
  }
}

export const viewModelTermAcceptedToModel = (view) => {
  try {
    if (!view) return null

    const model = Object.assign({}, Model)

    model.Uuid = view.uuid
    model.Active = view.active
    model.Booking = view.booking
    model.Integrator = view.integrator
    model.TermAccepted = view.termAccepted
    model.HeadOffice = view.headOffice
    model.Benefit = view.benefit
    model.Online = view.online

    return model
  } catch (error) {
    throw new Error(`UserMapper - viewModelTermAcceptedToModel = ${error}`)
  }
}
