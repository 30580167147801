import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, Redirect } from 'react-router-dom'
import { localGet, localRemove } from '../../lib/session'
import { setStatusAuth } from '../../actions/user'

class PrivateRoute extends Component {
  render() {
    const rest = {
      name: this.props.name,
      path: this.props.path,
      location: this.props.location,
      computedMatch: this.props.computedMatch
    }

    let auth = !this.props.authUser.state && this.props.authUser.data != null
    const sessionAuth = localGet('AUTH_TOKEN')
    const sessionTerm = localGet('TERM')

    if (!auth && sessionAuth && sessionTerm?.TermAccepted) {
      this.props.setStatusAuth(sessionAuth)
    }

    return (
      <div>
        {auth ? (
          <Route {...rest} component={this.props.component} />
        ) : (
          <>
            <Redirect to={{ pathname: '/login' }} />
            {localRemove('TERM')}
          </>
        )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ setStatusAuth }, dispatch)

const mapStateToProps = (state) => ({
  authUser: state.userState.authUser
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
