import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Slider from 'react-slick'
import moment from 'moment'
import { setCookie, parseCookies } from 'nookies'

export const FeeChangeNotice = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleAlreadyRead = () => {
    const expires = moment().endOf('day').toDate()

    setCookie(null, 'feeNoticeAlreadyDisplayed', 'true', { expires })

    handleClose()
  }

  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500
  }

  useEffect(() => {
    const cookies = parseCookies()

    const isInEffect = moment().isBefore('2022-11-01')
    const feeNoticeAlreadyDisplayed = cookies?.feeNoticeAlreadyDisplayed

    if (!feeNoticeAlreadyDisplayed && isInEffect) {
      handleShow()
    }
  }, [])

  return (
    <Modal show={show} dialogClassName="feeModal">
      <Modal.Header>
        <Modal.Title>Aviso importante</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ padding: '42px 82px' }}>
        <Slider {...settings}>
          {Array(10)
            .fill(null)
            .map((_, idx) => (
              <img key={idx} src={`/assets/imgs/feeInformation/${idx + 1}.png`} />
            ))}
        </Slider>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleAlreadyRead}>
          Li e estou ciente das mudanças
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
