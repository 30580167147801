import React from 'react'
import PropTypes from 'prop-types'
import { Container, Figure, Card } from 'react-bootstrap'

import GENERAL from '../const/general'

function DefaultView({ children }) {
  return (
    <Container fluid className="default-view p-0">
      <div className="default-view__dark-bg w-100">
        <div className="default-view__box-center">
          <Figure className="default-view__logo d-flex justify-content-center">
            <Figure.Image
              className="default-view__logo__icon"
              width={86}
              height={86}
              alt={GENERAL.BRAND}
              src={GENERAL.LOGO_URL}
            />
            <Figure.Caption className="default-view__logo__txt pt-3 pl-3">
              <div className="h4 mb-0">{GENERAL.SITE_NAME}</div>
              <div className="h2 mb-0">
                <strong>{GENERAL.BRAND}</strong>
              </div>
            </Figure.Caption>
          </Figure>

          <Card className="default-view__content m-auto">{children}</Card>
        </div>
      </div>
    </Container>
  )
}

DefaultView.defaultProps = {
  children: null
}

DefaultView.propTypes = {
  children: PropTypes.node
}

export default DefaultView
