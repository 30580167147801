import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Form,
  Media
} from 'reactstrap'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  authDataUser,
  getTermAccepted,
  updateTermAccepted,
  resetUserState
} from '../../actions/user'
// import { resetStoreKeys } from "../../actions/pix";
import { isLoggedIn } from '../../service/user'
import Loading from '../utils/loading'
import Model from '../../model/login'
import { modelToAuthViewModel } from '../../mapper/user'
import LOGIN from '../../const/login'
import PERMISSION from '../../const/userPermission'
import IconMail from '../../components/icons/mail'
import IconLock from '../../components/icons/lock'
import IconFaq from '../../components/icons/faq'
import ReactGA from 'react-ga'
import IconButton from '@material-ui/core/IconButton'
import CommonQuestions from '../../views/commonQuestions'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import { saveLoginCount } from '../../api/feedback'
import { localGet } from '../../lib/session'
import ReCAPTCHA from 'react-google-recaptcha-enterprise'
import { Height } from '@material-ui/icons'

class Login extends Component {
  constructor(props, context) {
    super(props, context)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleLabelOnClick = this.handleLabelOnClick.bind(this)
    this.handleAcceptTerm = this.handleAcceptTerm.bind(this)
    this.state = {
      ...Model,
      Redirected: false,
      errorLogin: '',
      modal: false,
      AuthUser: false,
      TermAcceptedByUser: false,
      Loading: false
    }
  }

  componentDidMount() {
    const p = this.props
    p.resetUserState()
    // p.resetStoreKeys();
  }

  componentWillUnmount() {
    const authLocal = localGet('AUTH_TOKEN')
    const { data } = this.props.authUser
    if (authLocal && data && authLocal?.Uuid === data?.Uuid) {
      saveLoginCount({
        uuid: data.Uuid,
        email: data.Email,
        type: data.Type
      })
    }
  }

  async handleSubmit(event, recaptcha) {
    event.preventDefault()
    const token = await recaptcha.current.executeAsync()

    if (token) {
      const p = this.props

      const state = modelToAuthViewModel(this.state, token)

      p.authDataUser(state)
      this.setState({
        LoadingAnimation: true
      })
    }
  }

  handleLabelOnClick() {
    const { termAcceptedModal } = this.state
    const nextState = {
      ...this.state,
      termAcceptedModal: !termAcceptedModal
    }
    this.setState(nextState)
  }

  handleAcceptTerm() {
    const { updateTermAccepted: updateTermAcceptedFunc, termAccepted, authUser } = this.props

    if (authUser.data.Type === PERMISSION.portal) {
      updateTermAcceptedFunc(termAccepted.data)
      this.setState({ LoadingAnimation: true, TermAcceptedByUser: false })
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { authUser } = nextProps
    const { LoadingAnimation } = nextState
    if (!!authUser?.data && !!authUser?.data?.error && LoadingAnimation) {
      this.setState({
        LoadingAnimation: false,
        errorLogin: authUser.data.error
      })
    }
  }

  render() {
    const {
      authUser,
      termAccepted,
      getTermAccepted: getTermAcceptedFunc,
      updateTermAcceptedResult,
      history
    } = this.props

    const {
      Email,
      Password,
      LoadingAnimation,
      errorLogin,
      TermAcceptedByUser,
      termAcceptedModal,
      modal
    } = this.state

    if (
      !!authUser.data &&
      !authUser.data.error &&
      !termAccepted &&
      authUser.data.Type === PERMISSION.portal
    ) {
      getTermAcceptedFunc(authUser.data)
    }

    if (!!termAccepted && !!termAccepted.data && !termAccepted?.data.Active && LoadingAnimation) {
      this.setState({
        LoadingAnimation: false,
        errorLogin: 'Loja desativada!'
      })
      ReactGA.event({
        category: 'Login',
        action: 'Loja desativada'
      })
      toast.error(() => <>"Loja desativada!"</>, {
        onClose: () => {}
      })
      return <Redirect to={{ pathname: '/login' }} />
    } else if (
      !!authUser.data &&
      !authUser.data.error &&
      (authUser.data.Type === PERMISSION.backoffice ||
        (!!termAccepted &&
          !!termAccepted.data &&
          termAccepted?.data.Active &&
          !!termAccepted.data.TermAccepted))
    ) {
      if (authUser.data.Type === PERMISSION.backoffice) {
        ReactGA.event({
          category: 'Login',
          action: 'Usuário de Backoffice'
        })
        return <Redirect to={{ pathname: '/busca-parceiro' }} />
      }

      if (
        !!authUser.data &&
        !authUser.data.Authorized &&
        authUser.data.Type === PERMISSION.portal
      ) {
        ReactGA.event({
          category: 'Login',
          action: 'Usuário ainda sem permissão para acessar o Portal'
        })
        return <Redirect to={{ pathname: '/em-breve' }} />
      }

      ReactGA.event({
        category: 'Login',
        action: 'Usuário de Portal'
      })

      return <Redirect to={{ pathname: '/' }} />
    }

    if (
      isLoggedIn() &&
      !!authUser.data &&
      !authUser.data.error &&
      LoadingAnimation &&
      !!termAccepted &&
      !!termAccepted.data &&
      !termAccepted.data.TermAccepted &&
      !termAcceptedModal
    ) {
      if (termAccepted.data.Benefit) {
        this.setState({ termAcceptedModal: true })
        this.handleAcceptTerm()
      } else {
        this.setState({ LoadingAnimation: false })
        history.push('/terms')
      }
    }

    if (updateTermAcceptedResult && updateTermAcceptedResult.data && authUser.data) {
      if (updateTermAcceptedResult.data.success && !TermAcceptedByUser) {
        getTermAcceptedFunc(authUser.data)
        this.setState({ TermAcceptedByUser: true })
      } else if (!updateTermAcceptedResult.data.success) {
        ReactGA.event({
          category: 'Login',
          action: 'Faltando aceitação de termo'
        })
        toast.error(() => <>{updateTermAcceptedResult.data.error}</>, {
          onClose: () => <Redirect to={{ pathname: '/login' }} />
        })
      }
    }

    const handleModalCommonQuestions = () => {
      this.setState({ modal: true })

      ReactGA.event({
        category: 'Login',
        action: 'Perguntas Frequentes tela de Login'
      })
    }

    if (LoadingAnimation) return <Loading msg={LOGIN.LOADING} />

    const recaptchaRef = React.createRef()

    return (
      <div className="app back-logo flex-row align-items-center">
        <Container>
          <Form onSubmit={(e) => this.handleSubmit(e, recaptchaRef)}>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_API_RECAPTCHA}
            />
            <Row className="justify-content-center">
              <Col md="6">
                <div className="container-logo mx-auto d-flex justify-content-center">
                  <Media
                    className="logo d-block mb-4"
                    object
                    src="/assets/imgs/logo_transparent.png"
                    alt="Logo"
                    style={{ width: '90px', height: '90px' }}
                  />
                  <p className="title h4 mb-4 text-white d-flex align-items-center flex-wrap">
                    <span className="w-100">{LOGIN.LOGO_TITLE}</span>
                    <span >{LOGIN.LOGO_SUB_TITLE}</span>
                  </p>
                </div>
                <CardGroup>
                  <Card className="px-3 pt-3 pt-2 box-login">
                    <CardBody>
                      <Row className="justify-content-center">
                        <Col md="11" className="p-0">
                          <p className="title mt-2 text-center h4 text-primary">{LOGIN.TITLE}</p>
                        </Col>
                        <Col md="1" className="p-0">
                          <IconButton
                            color="default"
                            component="span"
                            onClick={handleModalCommonQuestions}
                          >
                            <IconFaq size="26" color="#003863" />
                          </IconButton>
                        </Col>
                      </Row>

                      <p className="error-text text-danger mb-1">{errorLogin}</p>

                      <InputGroup className="mb-5">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            className={`pt-2 pl-3 pr-0 ${errorLogin ? 'border-danger' : ''}`}
                          >
                            <IconMail color="var(--color-blue-gray)" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className={`px-3 ${Email && ' full'} ${
                            errorLogin ? 'border-danger' : ''
                          }`}
                          type="text"
                          placeholder=""
                          required="required"
                          id="Email"
                          name="Email"
                          value={Email}
                          onChange={(e) =>
                            this.setState({
                              Email: e.target.value,
                              errorLogin: ''
                            })
                          }
                        />
                        <span className="placeholder">E-mail</span>
                        {errorLogin && <i className="error-icon">!</i>}
                      </InputGroup>
                      <InputGroup className="mt-4 mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            className={`pl-3 pr-0 ${errorLogin ? 'border-danger' : ''}`}
                          >
                            <IconLock color="var(--color-blue-gray)" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className={`px-3 ${Password && ' full'} ${
                            errorLogin ? 'border-danger' : ''
                          }`}
                          type="password"
                          placeholder=""
                          required="required"
                          id="Password"
                          name="Password"
                          value={Password}
                          onChange={(e) =>
                            this.setState({
                              Password: e.target.value,
                              errorLogin: ''
                            })
                          }
                        />
                        <span className="placeholder">Senha</span>
                        {errorLogin && <i className="error-icon">!</i>}
                      </InputGroup>
                      <Row>
                        <Col xs="12" className="text-right pt-2 pb-3">
                          <Link
                            to="/login/forgot-password"
                            className="forgot-link"
                            style={{ fontSize: '18px' }}
                          >
                            {LOGIN.FORGOT_BUTTON}
                          </Link>
                        </Col>
                        <Col xs="12">
                          <Button disabled={!Email || !Password} className="px-4 py-3 w-100">
                            {LOGIN.LOGIN_BUTTON}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Form>
          <Dialog
            onClose={() => this.setState({ modal: false })}
            aria-labelledby="customized-dialog-title"
            open={modal}
          >
            <Box p="10px">
              <CommonQuestions
                handleClose={() => this.setState({ modal: false })}
                modal
                modalTitle={LOGIN.MODAL_TITLE}
              />
            </Box>
          </Dialog>
        </Container>
      </div>
    )
  }
}

Login.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.object }).isRequired,
  authDataUser: PropTypes.func.isRequired,
  authUser: PropTypes.shape({ data: PropTypes.object, state: PropTypes.bool }).isRequired,
  getTermAccepted: PropTypes.func.isRequired,
  termAccepted: PropTypes.shape({
    data: PropTypes.object,
    state: PropTypes.bool
  }),
  updateTermAccepted: PropTypes.func.isRequired,
  updateTermAcceptedResult: PropTypes.shape({
    data: PropTypes.object,
    state: PropTypes.bool
  })
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authDataUser,
      getTermAccepted,
      updateTermAccepted,
      resetUserState
      // resetStoreKeys,
    },
    dispatch
  )

const mapStateToProps = (state) => ({
  authUser: state.userState.authUser,
  termAccepted: state.userState.termAccepted,
  updateTermAcceptedResult: state.userState.updateTermAcceptedResult
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
