import Config from '../config/environments'
import { localSet } from '../lib/session'
import { SET_BACKOFFICE_STORE } from '../actionTypes/backoffice'

export const setBackofficeStore = (data) => async (dispatch) => {
  const base = {
    type: SET_BACKOFFICE_STORE,
    payload: { storeId: data, state: false }
  }

  if (data) {
    localSet('BACKOFFICE_STORE', data, Config.EXPIRATION_AUTH)
  }
  dispatch(base)
}

export const setUrlBackoffice = (readOnly, url) => {
  if (readOnly) {
    return `${Config.BACKOFFICE.URL}${url}`
  }

  return url
}
