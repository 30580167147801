import React, { Component } from 'react'

export default function LockMail({ size = 19.5, color = 'black' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size * 1.103}
      viewBox={`0 0 ${size} ${size * 1.103}`}
    >
      <g transform={`translate(-${size * 0.1154} -${size * 0.0642})`}>
        <rect
          fill="none"
          stroke={color}
          strokeWidth={size * 0.077}
          width={size * 0.924}
          height={size * 0.565}
          rx={size * 0.103}
          transform={`translate(${size * 0.154} ${size * 0.565})`}
        />
        <path fill="none" stroke={color} strokeWidth={size * 0.077} d="M7,11V7A5,5,0,1,1,17,7v4" />
      </g>
    </svg>
  )
}

LockMail.propTypes = Component.propTypes
