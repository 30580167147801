import Model from '../model/store'

function setModelView(model, view) {
  model.uuid = view.uuid
  model.active = view.active
  model.storeOwner = view.storeOwner
  model.name = view.name
  model.fantasyName = view.fantasyName
  model.document = view.document
  model.email = view.email
  model.type = view.type
  model.category = view.category
  model.headStoreId = view.headStoreId
  model.headOffice = view.headOffice
  model.redirectUrl = view.redirectUrl
  model.hasRedirectUrl = view.hasRedirectUrl
  model.integrator = view.integrator
  model.about = view.about
  model.rules = view.rules

  model.address = view.address
  model.shopping = view.shopping
  model.phones = view.phones

  model.photo = view.photo
  model.background = view.background
  model.listImage = view.listImage
  model.thumbUrl = view.thumbUrl
  model.backgroundUrl = view.backgroundUrl
  model.listImageUrl = view.listImageUrl

  model.bank = view.bank
  model.agency = view.agency
  model.agencyCheckNumber = view.agencyCheckNumber
  model.accountNumber = view.accountNumber
  model.accountCheckNumber = view.accountCheckNumber
  model.storeCashback = view.storeCashback
  model.paymentMethods = view.paymentMethods
}

export const viewModelToModel = (view) => {
  try {
    if (!view) return null

    const model = Object.assign({}, Model)

    setModelView(model, view)

    return model
  } catch (error) {
    throw new Error(`StoreMapper - viewModelToModel = ${error}`)
  }
}

export const viewsModelToModels = (models) => {
  try {
    if (!models) return null

    const views = []

    models.stores.forEach(async (model) => {
      const view = viewModelToModel(model)

      views.push(view)
    })

    return views
  } catch (error) {
    throw new Error(`StoreMapper - viewsModelToModels = ${error}`)
  }
}

export const modelToViewModel = (model) => {
  try {
    if (!model) return null

    const view = {}

    view.uuid = model.uuid

    return view
  } catch (error) {
    throw new Error(`StoreMapper - modelToViewModel = ${error}`)
  }
}

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null

    const views = []

    models.forEach(async (model) => {
      const view = modelToViewModel(model)

      views.push(view)
    })

    return views
  } catch (error) {
    throw new Error(`StoreMapper - modelsToViewsModel = ${error}`)
  }
}

export const viewModelToState = (view) => {
  try {
    if (!view) return null

    const model = Object.assign({}, Model)

    model.Uuid = view.Uuid || ''

    return model
  } catch (error) {
    throw new Error(`StoreMapper - viewModelToState = ${error}`)
  }
}
