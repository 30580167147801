import {
  DETAIL_TRANSACTION,
  LIST_TRANSACTION,
  DAILY_TRANSACTION,
  DAILY_TRANSACTION_SUMMARY_BY_DATE,
  DAILY_TRANSACTION_LIST_BY_DATE,
  DETAIL_ORDER_TRANSACTION,
  TRANSACTION_TO_TRANSACTION_TABLE,
  TRANSACTION_TO_TRANSACTION_SUMMARY
} from '../actionTypes/transaction'

const BASE = {
  state: false,
  data: null
}

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: []
  }
}

const INITIAL_STATE = {
  detailTransaction: BASE,
  detailOrderTransaction: BASE,
  listTransaction: BASE_PAGINATION,
  dailyTransaction: BASE,
  listByDateTransaction: BASE,
  transactionToTransactionTable: BASE,
  transactionToTransactionSummary: BASE
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DETAIL_TRANSACTION:
      return { ...state, detailTransaction: action.payload }
    case DETAIL_ORDER_TRANSACTION:
      return { ...state, detailOrderTransaction: action.payload }
    case LIST_TRANSACTION:
      return { ...state, listTransaction: action.payload }
    case DAILY_TRANSACTION:
      return { ...state, dailyTransaction: action.payload }
    case DAILY_TRANSACTION_SUMMARY_BY_DATE:
      return { ...state, summaryByDateTransaction: action.payload }
    case DAILY_TRANSACTION_LIST_BY_DATE:
      return { ...state, listByDateTransaction: action.payload }
    case TRANSACTION_TO_TRANSACTION_TABLE:
      return { ...state, transactionToTransactionTable: action.payload }
    case TRANSACTION_TO_TRANSACTION_SUMMARY:
      return { ...state, transactionToTransactionSummary: action.payload }
    default:
      return state
  }
}
