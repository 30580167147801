export default {
  LOADING: 'Carregando...',
  TITLE: 'Login',
  LOGO_TITLE: 'Portal Parceiros',
  LOGO_SUB_TITLE: 'KMV',
  LOGIN: 'Faça seu login',
  LOGIN_TEXT: '',
  LOGIN_TEXT_ERROR: 'Usuário ou senha não conferem.',
  LOGIN_BUTTON: 'Entrar',
  FORGOT_BUTTON: 'Esqueci minha senha',
  FORGOT_MSG: 'Para recuperar sua senha, entre com contato com o Administrador do Sistema.',
  MODAL_TITLE: 'Perguntas Frequentes'
}
