import PAGE_CONFIG from '../const/my-data'
import { CnpjMask } from '../lib/mask'

function mapProps(data) {
  const {
    REGISTRATION: { LABELS }
  } = PAGE_CONFIG

  return Object.entries(data).reduce((acc, [name, value]) => {
    if (value) acc.push({ label: LABELS[name], value })

    return acc
  }, [])
}

function associatedMapProps(data = []) {
  return data.reduce(
    (
      acc,
      { document, fantasyName, name, storeOwner, address, phone, zipcode, bankData, location }
    ) => {
      acc.push({
        storeName: name,
        header: mapProps({ document, fantasyName, name }),
        body: mapProps({
          storeOwner,
          address,
          phone,
          zipcode,
          bankData,
          location
        })
      })

      return acc
    },
    []
  )
}

function organizeRegistrationData({
  bank,
  document,
  agency,
  name,
  fantasyName,
  agencyCheckNumber,
  accountNumber,
  accountCheckNumber,
  phones,
  storeOwner,
  address: {
    street,
    streetNumber: number,
    neighborhood,
    city,
    complement = '',
    state,
    zipCode: zipcode,
    latitude,
    longitude
  },
  ...rest
}) {
  return {
    ...rest,
    document: document && CnpjMask(document),
    phone:
      phones && phones.map(({ areaCode, number: numberPhone }) => `(${areaCode}) ${numberPhone} `),
    fantasyName: fantasyName || '',
    address:
      street &&
      `${street}, ${number}${
        complement && ` - ${complement}`
      } - ${neighborhood}\n${city} / ${state} - Brasil`,
    name: name || '',
    zipcode: zipcode || '',
    storeOwner: storeOwner ? `${storeOwner.firstName || ''} ${storeOwner.lastName || ''}` : '',
    location: latitude && longitude && `${latitude} ${longitude}`,
    bankData:
      bank &&
      `${bank.number} - ${bank.name}\nAgência: ${agency}-${
        agencyCheckNumber || 0
      }\nConta: ${accountNumber}-${accountCheckNumber || 0}
    `
  }
}

function mapStoreData(data, type) {
  if (type === 'store') {
    const newData = {
      ...data,
      address: {
        ...data.address,
        zipCode: data.address.zipcode,
        streetNumber: data.address.number
      }
    }
    const { email, integratorCode, redirectUrl, ...rest } = organizeRegistrationData(newData)

    const { address, document, zipcode, fantasyName, phone, name } = organizeRegistrationData(
      newData
    )

    const year = 2021
    return {
      indentification: mapProps({ email, integratorCode, redirectUrl }),
      general: mapProps({ ...rest }),
      dirf_information: mapProps({ year, address, document, zipcode, fantasyName, phone, name })
    }
  }

  const organizedProps = data.map((elm) => organizeRegistrationData(elm))
  return associatedMapProps(organizedProps)
}

export default mapStoreData
