import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AppNavbarBrand } from '@coreui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import FloatingModal from '../components/floatingModal'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledDropdown,
  Container
} from 'reactstrap'
import { withRouter, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import PERMISSION from '../const/userPermission'

import { setStatusAuth } from '../actions/user'
import { getDataBranch, setDefaultStore } from '../actions/branch'
import { setBackofficeStore } from '../actions/backoffice'
import { checkDocuments } from '../actions/store'
import { localSet, localGet, localRemove } from '../lib/session'
import Config from '../config/environments'
import { logout } from '../service/user'
import rules from './rules'
import ReactGA from 'react-ga'

class DefaultHeader extends Component {
  constructor(props, context) {
    super(props, context)
    this.logoutHandle = this.logoutHandle.bind(this)
    this.state = {
      storeName: '',
      backofficeStore: localGet('BACKOFFICE_STORE'),
      isOpenModal: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { props } = this
    const {
      defaultStore: { data: nextDefaultStore }
    } = nextProps
    const {
      defaultStore: { data: prevDefaultStore }
    } = props

    if (nextDefaultStore && nextDefaultStore !== prevDefaultStore) {
      const storeName =
        nextDefaultStore.FantasyName?.length > 25
          ? `${nextDefaultStore.FantasyName.substring(0, 25)}...`
          : nextDefaultStore.FantasyName

      this.setState({ storeName })
      return true
    }

    return false
  }

  shouldComponentUpdate(nextProps, nextState) {
    const checkFeedback = localGet('CHECK_FEEDBACK')
    if (nextProps && nextState && nextState.isOpenModal !== checkFeedback) {
      const { Type } = this.props.authUser.data
      if (Type === 'PORTAL' && checkFeedback) {
        setTimeout(() => {
          this.setState({ isOpenModal: true })
        }, 60000)
      }
      return false
    }
    return true
  }

  logoutHandle(event) {
    event.preventDefault()
    const divChat = document.getElementById('blip-chat-container')
    if (divChat) divChat.remove()
    const p = this.props
    localRemove('CAMPAIN')
    logout()
    p.setStatusAuth()
    localStorage.removeItem('blipSdkUAccount')
    localStorage.removeItem('AUTH_TOKEN')
  }

  checkOrbitallDocuments(modelBranch) {
    const { props } = this
    let store
    const isCheckDocuments = localGet('CHECK_DOCUMENTS')

    if (modelBranch.length === 1) {
      store = modelBranch[0]
    } else {
      store = modelBranch.filter((e) => e.HeadOffice)[0] || modelBranch[0]
    }
    if (
      !isCheckDocuments &&
      store &&
      !store.IPP &&
      props.authUser.data.Type !== PERMISSION.backoffice
    ) {
      props.checkDocuments(store.Uuid)
      localSet('CHECK_DOCUMENTS', true, Config.EXPIRATION_AUTH)
    }
  }

  componentWillUnmount() {
    this.props.setDefaultStore()
    this.props.setBackofficeStore()
    localRemove('CHECK_FEEDBACK')
  }

  componentDidMount() {
    const backofficeBranch = localGet('BACKOFFICE_BRANCH')

    if (backofficeBranch) {
      this.props.getDataBranch(backofficeBranch)
    }
    this.props.authUser.data.Type === 'PORTAL' && this.props.getDataBranch(backofficeBranch)
    this.props.setBackofficeStore(this.state.backofficeStore)
    // document.getElementById("requestBot").click()
  }

  render() {
    const buscaParceiro = window.location.pathname === '/busca-parceiro'
    const isProdURL = window.location.hostname.includes('parceiroskmv')
    const { storeName, isOpenModal } = this.state
    const { authUser, listBranch, children, history } = this.props

    if (!authUser) {
      return <Redirect to={{ pathname: '/login' }} />
    }

    const modelBranch = listBranch.data

    this.checkOrbitallDocuments(modelBranch || [])

    const stores = localGet('STORES')
    if (!stores) localSet('STORES', modelBranch, Config.EXPIRATION_AUTH)

    const { location } = history

    const hideHeader = rules[location.pathname]?.hideHeader


    /*if (isProdURL) {
      children.props.navConfig.items = children.props.navConfig.items.filter(
        (item) => item.name !== "Delivery",
      );
    }*/
    if (stores) {
      const hasDelivery = stores.filter((item) => item.Delivery)
      if (hasDelivery.length === 0) {
        children.props.navConfig.items = children.props.navConfig.items.filter(
          (item) => item.name !== 'Delivery'
        )
      }
    }

    const handleClickCommonQuestions = () => {
      history.push('/perguntas-frequentes')
      ReactGA.event({
        category: 'Header',
        action: 'Perguntas Frequentes usuário autenticado'
      })
    }

    return (
      <Container>
        {isOpenModal && <FloatingModal />}
        <img style={{ width: '90px', height:'90px' }} src="/assets/imgs/logo_transparent.png" alt="KMV" />
        {!hideHeader && (
          <Nav className="app-menu ml-auto app-header-menu-dropdown">
            {children}
            {!isProdURL && !authUser.readOnly && (
              <UncontrolledDropdown direction="down">
                {/* <DropdownToggle nav>
                  <span>
                    Área Pix{' '}
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="ml-1"
                      style={{ verticalAlign: 'middle' }}
                    />
                  </span>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdownMenuSite dropDownLastChildBlue mt-0"
                  right
                  style={{ left: 'auto' }}
                >
                  <DropdownItem onClick={() => history.push('/#')}>Pix Manual/Chave</DropdownItem>
                  <DropdownItem onClick={() => history.push('/#')}>Receber Pix</DropdownItem>
                  <DropdownItem onClick={() => history.push('/pix/gerenciamento-chaves')}>
                    Gerenciar Chaves
                  </DropdownItem>
                </DropdownMenu> */}
              </UncontrolledDropdown>
            )}
          </Nav>
        )}
        <Nav navbar>
          <button
            type="button"
            id="requestBot"
            onClick={handleClickCommonQuestions}
            className="p-0 bg-transparent border-0 "
          >
            <i className="info">
              <img src="/assets/icons/faq-icon.svg" alt="FAQ" />{' '}
            </i>
          </button>
          <UncontrolledDropdown direction="down">
            <DropdownToggle nav>
              <span>
                <i className="cil-user" />
              </span>
            </DropdownToggle>
            <DropdownMenu className="dropdownMenuSite" right style={{ left: 'auto' }}>
              {!!storeName && (
                <DropdownItem className="text-theme-blue-grey" header>
                  {storeName}
                </DropdownItem>
              )}
              {!buscaParceiro && (
                <DropdownItem onClick={() => history.push('/meus-dados')}>Meus dados</DropdownItem>
              )}
              {authUser?.data?.Type === 'PORTAL' && (
                <DropdownItem onClick={() => history.push('/documentos')}>Documentos</DropdownItem>
              )}
              {authUser?.data?.Type === 'PORTAL' && (
                <DropdownItem onClick={() => history.push('/produtos')}>Produtos</DropdownItem>
              )}
              {!buscaParceiro && authUser.readOnly && (
                <DropdownItem onClick={() => history.push('/busca-parceiro')}>
                  Acessar outra loja
                </DropdownItem>
              )}
              <DropdownItem onClick={this.logoutHandle}>Sair</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Container>
    )
  }
}

DefaultHeader.propTypes = {
  children: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  setStatusAuth: PropTypes.func.isRequired,
  getDataBranch: PropTypes.func.isRequired,
  checkDocuments: PropTypes.func.isRequired,
  authUser: PropTypes.shape({
    data: PropTypes.object,
    state: PropTypes.bool
  }).isRequired,
  listBranch: PropTypes.shape({
    data: PropTypes.array,
    state: PropTypes.bool
  }).isRequired,
  defaultStore: PropTypes.shape({
    data: PropTypes.array || PropTypes.object,
    state: PropTypes.bool
  }).isRequired
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setStatusAuth,
      getDataBranch,
      setDefaultStore,
      checkDocuments,
      setBackofficeStore
    },
    dispatch
  )

const mapStateToProps = (state) => ({
  authUser: state.userState.authUser,
  listBranch: state.branchState.listBranch,
  defaultStore: state.branchState.defaultStore
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultHeader))
