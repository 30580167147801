export default {
  LIST: {
    TITTLE: {
      TEXT: 'Portal Parceiro',
      BRAND: 'KMV'
    },
    CONTENT: {
      TITLE: 'Condições Comerciais',
      LABEL_CASH: 'Não se aplica',
      LABEL_SAME_AS_PAYMENT: 'Casada com o pagamento na conta do KMV'
    },
    LABEL_BUTTON: 'Declaro que li e aceito os termos acima',
    LOADING: 'Aguarde ...',
    REQUEST_MSG: {
      ERROR: 'Falha ao montar os termos e condições comerciais',
      SUCCESS: 'Tudo certo! Faça seu login novamente para ter acesso'
    }
  },
  GENERAL_DATA: {
    BOX_TITLE: 'Informações gerais ',
    DATA: [
      [
        {
          title: 'Taxa de juros por parcela',
          info: '1% a.m.'
        },
        {
          title: 'Quantidade máxima de parcelas',
          info: '12X'
        },
        {
          title: 'Valor mínimo de parcelas',
          info: 'R$ 50,00'
        }
      ],
      [
        {
          title: 'Frequência de pagamento',
          info: 'Semanal, às terças',
          alert:
            'Os pagamentos de recebíveis serão realizados conforme contrato vigente, seguindo a regra acima, desde que o dia escolhido não seja um feriado.'
        },
        {
          title: 'Tarifa TED',
          info: 'R$ 0,00',
          alert:
            'Você não possui tarifas para transferências TED. Tentativas feitas e não realizadas por um erro no banco destinatário não serão cobradas.'
        }
      ]
    ]
  },
  DETAILS_CONFIG: {
    boxTitle: 'Formas de pagamento, comissões e liquidações',
    header: [
      'Meios de pagamento',
      'Taxa de comissão do app',
      'Prazo de pagamento na conta KMV',
      'Programação de transferência (TED)'
    ]
  },
  CHECK_LIST_CONFIG: [
    {
      name: 'comerciais',
      label: 'Aceito as condições comerciais acima'
    },
    {
      name: 'pagamentos',
      label: 'Aceito os Termos e Condições de Contratação das Soluções de Pagamento',
      link:
        'https://197298744561-eai-vcmais-bucket.s3.us-east-1.amazonaws.com/terms-and-conditions/E-Ai%CC%81%20-Termos%20e%20Condic%CC%A7o%CC%83es%20de%20Contratac%CC%A7a%CC%83o%20Estabelecimentos%20%28Aceite%20App%29_.pdf?response-content-disposition=attachment&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEDwaCXNhLWVhc3QtMSJHMEUCIFwK0sAOkleyx%2B448Vp%2FWCv7c5y8XwVLEDTWc6QqffPxAiEA3ep55bY3b7yxzB7WsI8bG1VKnzB3vDx2i%2B7GhhWh8rYquQII9f%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwxOTcyOTg3NDQ1NjEiDF%2F3pcNVCFY91iv2OyqNAl%2B8liKIkcFP4E6SY%2Fo2CT%2F3MxemSvRvFNCUI3WvXBIj5yFh%2B6hS0dEc1f0QIsxwNvuajotkLXzpWdSljziFgjR417cuEXI0DmTEKsQLr%2FEu4%2FTYWVaAhNpiFVbSIaRSTp9R4twV1SRA0l%2BzMe6SaclMNdKJhRvNXnalw1K%2FBhPV5lrOFMDLv87%2B18vwupGiGnoI6B0NFucoe8X3CrXhTVty5FbahplR5WtkvzxNmrz%2BG%2FaaksW5NyAe181bhDS3IW89IT9UXgFI8k%2FEYuuGCT6R4BtlyoJ8%2FhSTrC%2BJ%2FVVz0bMcwbJhCKFKx%2FPE%2F8wod%2B2LoUezulk4uq%2FiZDYKvAoV3L9BAv4z3TLCSLikMOj3gfkFOs4CGGE0ZRZ%2BlY8RMFVCh2S64TU5NrtSllSBvOUvUKrUVN1brBtuHcPTrqjrqO%2FhRPXXmin1ncMruG0jROD4XBiezUEfWGgodbrsaR3UBbWGIp%2BjRI8lG31AKJ8Yq8xrRJ6n4bxc2dR9R9dN66ouuHdhKKNt767GuXuyPg1k6Kztncr8VDEUIXCDQ9%2Fd0%2Bz9csDb0kooZQeM3WZ4bucXvnKXD%2ByDqu1QRnxYS10pX8Bba7RlxX%2Fxpzy9Je9QqDCaDHcPteJQcOs1wnZ7vVjXCD9IBNandeP73%2BRkat291HFiSXGdo%2BVG7tjj5J7MoVSF4nH%2FCLWEsZneZml1VZ0LVSH4iJVvc7Pqpy%2F2gEnaOJME3hJPtvtME1%2BqoCkKuRptsRkam0jMz%2FlQZC%2FO%2F379qJGG%2FRUgJbzYlr5fyDLz5Wq4l%2Fmoy1aOg7ANOAYcZnXm7Q%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20200728T201838Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAS337L5TYWXGFNXNW%2F20200728%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=357dc2d3c73981f251f80a569dae5d9fad4cc5ec0b479d053aa3f1a488b4ea20'
    }
  ]
}
