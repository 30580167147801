import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { Container } from 'reactstrap'
import { localGet } from '../lib/session'
import PERMISSION from '../const/userPermission'
import { AppBreadcrumb, AppFooter, AppHeader, AppSidebarNav as AppMenuNav } from '@coreui/react'
import navigation from '../routes/nav'
import routes from '../routes/routes'
import DefaultFooter from './defaultFooter'
import DefaultHeader from './defaultHeader'
import { FeeChangeNotice } from '../components/FeeChangeNotice'
import AlertStrapiBanner from '../components/alertStrapiBanner'

class DefaultLayout extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  render() {
    const { authUser, location, ...rest } = this.props
    const { readOnly } = authUser
    const authToken = localGet('AUTH_TOKEN')

    let redirect = {
      from: '/',
      to: '/busca-parceiro'
    }

    const authLocal = localGet('AUTH_TOKEN')
    if (!!authLocal && !authLocal.Authorized && authLocal.Type === PERMISSION.portal) {
      redirect = {
        from: '/',
        to: '/em-breve'
      }
      return <Redirect to={{ pathname: '/em-breve' }} />
    }

    if (!readOnly) {
      redirect = {
        from: '/',
        to: '/vendas-hoje'
      }
    }

    // const newNavigation = navigation.items.filter((item) => {
    //   if (authToken.Type !== 'BACKOFFICE') {
    //     return item.url !== '/gestao-de-campanhas'
    //   } else {
    //     return {
    //       ...item,
    //     }
    //   }
    // })

    return (
      <div className="app">
        <FeeChangeNotice />
        <AppHeader fixed>
          <DefaultHeader>
            <AppMenuNav
              className="app-header-menu nav-link-custom"
              navConfig={{ items: navigation.items }}
              {...rest}
            />
          </DefaultHeader>
        </AppHeader>
        <div className="app-body">
          <main className="main">
            {location.pathname !== '/busca-parceiro' && <Container>

              <AlertStrapiBanner />
            </Container>
            }
            <AppBreadcrumb
              className="app-breadcrumb container px-0 border-bottom-0"
              appRoutes={routes}
            />
            <Container>

              <Switch>
                {routes.map((route) =>
                  route.component ? (
                    <Route
                      key={route.name}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => <route.component {...props} />}
                    />
                  ) : null
                )}
                <Redirect {...redirect} />
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter className="mt-5 bg-primary">
          <DefaultFooter />
        </AppFooter>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

const mapStateToProps = ({ userState }) => ({
  authUser: userState.authUser
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultLayout))
