import React from 'react'
import PropTypes from 'prop-types'

function Loading({ fixed, bgNone }) {
  let addClass = ''
  if (fixed) addClass += 'fixed'
  if (bgNone) addClass += 'bg-none'

  return <div className={`component-loading ${addClass}`} />
}

Loading.defaultProps = {
  fixed: false,
  bgNone: false
}
Loading.propTypes = {
  fixed: PropTypes.bool,
  bgNone: PropTypes.bool
}

export default Loading
