import Loadable from 'react-loadable'
import Loading from '../views/utils/loading'
import DefaultLayout from '../containers/defaultLayout'

const ReceivableDailyList = Loadable({
  loader: () => import('../views/receivable/receivableDaily'),
  loading: Loading
})

const TransactionDetail = Loadable({
  loader: () => import('../views/transaction/transactionDetail'),
  loading: Loading
})

const TransactionToday = Loadable({
  loader: () => import('../views/transaction/transactionToday'),
  loading: Loading
})

const TransactionToTransaction = Loadable({
  loader: () => import('../views/transaction/transactionToTransaction'),
  loading: Loading
})

const TransactionDaily = Loadable({
  loader: () => import('../views/transaction/transactionDaily'),
  loading: Loading
})

const Mydata = Loadable({
  loader: () => import('../views/mydata/'),
  loading: Loading
})
const Documents = Loadable({
  loader: () => import('../views/documents/'),
  loading: Loading
})
const Products = Loadable({
  loader: () => import('../views/products/'),
  loading: Loading
})

const FindStore = Loadable({
  loader: () => import('../views/findstore'),
  loading: Loading
})

const KeyManagement = Loadable({
  loader: () => import('../views/pix/keyManagement'),
  loading: Loading
})

const CreateKeyForm = Loadable({
  loader: () => import('../views/pix/createKeyForm'),
  loading: Loading
})

// const DeliveryData = Loadable({
//   loader: () => import('../views/delivery'),
//   loading: Loading
// })

const CommonQuestions = Loadable({
  loader: () => import('../views/commonQuestions'),
  loading: Loading
})
const FinancialConciliation = Loadable({
  loader: () => import('../views/conciliation/'),
  loading: Loading
})

const CampaignManager = Loadable({
  loader: () => import('../views/CampaignManager/'),
  loading: Loading
})

const CampaignCreate = Loadable({
  loader: () => import('../views/campaignCreate/'),
  loading: Loading
})

const CampaignResume = Loadable({
  loader: () => import('../views/campaignResume/'),
  loading: Loading
})
const CampaignDetail = Loadable({
  loader: () => import('../views/campaignDetail/'),
  loading: Loading
})

const CampaignManagerFaq = Loadable({
  loader: () => import('../components/CampaignManagerFaq/CampaignManagerFaq'),
  loading: Loading
})

const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
    component: DefaultLayout
  },
  // {
  //   path: '/delivery',
  //   exact: true,
  //   name: 'Delivery',
  //   component: DeliveryData
  // },
  {
    path: '/conta',
    exact: true,
    name: 'Conta',
    component: ReceivableDailyList
  },
  {
    path: '/conta/detalhes/:uuid',
    exact: true,
    name: 'Detalhes da Venda',
    component: TransactionDetail
  },
  {
    path: '/vendas-hoje',
    exact: true,
    name: 'Vendas de hoje',
    component: TransactionToday
  },
  {
    path: '/vendas-hoje/venda-a-venda/:uuid',
    exact: true,
    name: 'Venda ',
    component: TransactionDetail
  },
  {
    path: '/vendas-hoje/detalhes/:uuid',
    exact: true,
    name: 'Detalhes da Venda',
    component: TransactionDetail
  },
  {
    path: '/vendas-fechadas',
    exact: true,
    name: 'Vendas Fechadas',
    component: TransactionDaily
  },
  {
    path: '/conciliacao',
    exact: true,
    name: 'Conciliação',
    component: FinancialConciliation
  },
  {
    path: '/gestao-de-campanhas',
    exact: true,
    name: 'A Oferta é Sua! ',
    component: CampaignManager
  },
  {
    path: '/gestao-de-campanhas/criar-campanha',
    exact: true,
    name: 'Criação de campanhas',
    component: CampaignCreate
  },
  {
    path: '/gestao-de-campanhas/resumo-campanha',
    exact: true,
    name: 'Resumo da solicitação da campanha',
    component: CampaignResume
  },
  {
    path: '/gestao-de-campanhas/detalhe-campanha',
    exact: true,
    name: 'Detalhe da campanha',
    component: CampaignDetail
  },
  {
    path: '/gestao-de-campanhas/faq-ajuda',
    exact: true,
    name: 'FAQ e Ajuda',
    component: CampaignManagerFaq
  },
  {
    path: '/vendas-fechadas/venda-a-venda',
    exact: true,
    name: 'Venda a venda',
    component: TransactionToTransaction
  },
  {
    path: '/vendas-fechadas/venda-a-venda/detalhes/:uuid',
    exact: true,
    name: 'Detalhes da Venda',
    component: TransactionDetail
  },
  {
    path: '/vendas-fechadas/detalhes/:uuid',
    exact: true,
    name: 'Detalhes da Venda',
    component: TransactionDetail
  },
  {
    path: '/meus-dados',
    exact: true,
    name: 'Meus dados',
    component: Mydata
  },
  {
    path: '/documentos',
    exact: true,
    name: 'Documentos',
    component: Documents
  },
  {
    path: '/produtos',
    exact: true,
    name: 'Produtos',
    component: Products
  },
  {
    path: '/busca-parceiro',
    exact: true,
    name: 'Parceiros',
    component: FindStore
  },
  {
    path: '/pix/gerenciamento-chaves',
    exact: true,
    name: 'Gerenciamento de chaves',
    component: KeyManagement
  },
  {
    path: '/pix/nova-chave',
    exact: true,
    name: 'Gerenciamento de chaves',
    component: CreateKeyForm
  },
  {
    path: '/perguntas-frequentes',
    exact: true,
    name: 'Perguntas Frequentes',
    component: CommonQuestions
  }
]

export default routes
