import { axiosInstance as axios } from '../lib/axios'
import Config from '../config/environments'

export const getTrackingId = async () => {
  const url = Config.GOOGLE_ANALYTICS.URL

  try {
    const resp = await axios.get(url)
    return { data: resp.data }
  } catch (err) {
    return { err }
  }
}
