export default {
  KEY_TYPES: [
    {
      id: 'PHONE',
      description: 'Celular'
    },
    {
      id: 'CNPJ',
      description: 'CNPJ'
    },
    {
      id: 'EMAIL',
      description: 'E-mail'
    },
    {
      id: 'EVP',
      description: 'Endereço Virtual de Pagamento (EVP)'
    }
  ],
  KEY_LABEL: {
    PHONE: 'Celular',
    CNPJ: 'CNPJ',
    EMAIL: 'E-mail',
    EVP: 'EVP',
    PORTABILITY_OWNERSHIP_CLAIMER: 'Processo de portabilidade e reivindicação'
  },
  KEY_STATUS: {
    PENDING: {
      DESCRIPTION: 'Cadastro pendente ',
      COLOR: 'primary'
    },
    PORTABILITY_OPEN: {
      DESCRIPTION: 'Portabilidade solicitada ',
      COLOR: 'secondary'
    },
    PORTABILITY_EXISTS: {
      DESCRIPTION: 'Início de portabilidade pendente ',
      COLOR: 'primary'
    },
    OWNERSHIP_OPEN: {
      DESCRIPTION: 'Reivindicação de posse solicitada ',
      COLOR: 'secondary'
    },
    OWNERSHIP_EXISTS: {
      DESCRIPTION: 'Início de reivindicação de posse pendente ',
      COLOR: 'primary'
    },
    OWNERSHIP_WAITING_RESOLUTION: {
      DESCRIPTION: 'Reivindicação de posse aguardando resposta ',
      COLOR: 'secondary'
    },
    PORTABILITY_WAITING_RESOLUTION: {
      DESCRIPTION: 'Portabilidade aguardando resposta ',
      COLOR: 'secondary'
    }
  },
  FAVORITE:
    'Essa chave é sua chave favorita, ela ficará visível na home Pix, como número e QR Code',
  HAS_MODAL: {
    PENDING: true,
    PORTABILITY_EXISTS: true,
    OWNERSHIP_EXISTS: true,
    OWNERSHIP_OPEN_OWNER: true,
    PORTABILITY_WAITING_RESOLUTION_OWNER: true,
    OWNERSHIP_WAITING_RESOLUTION_OWNER: true
  },
  CLAIM_TYPE: {
    PORTABILITY_EXISTS: 'PORTABILITY',
    OWNERSHIP_EXISTS: 'OWNERSHIP'
  },
  CLAIM_CANCEL_NOTIFICATION: {
    header: 'Você cancelou o processo de :type',
    textBody: 'A chave :key continuará vinculada a :type_message',
    date: ':date'
  },
  FIXED_DATE: 14,
  TYPE_ERROR: {
    PIX_KEY_OWNERSHIP_EXISTS: true,
    PIX_KEY_PORTABILITY_EXISTS: true,
    PROCESS_PENDING: true
  },
  MAPPER_CLAIMER: {
    OWNERSHIP_OPEN_CLAIMER: true,
    PORTABILITY_OPEN_CLAIMER: true,
    OWNERSHIP_WAITING_RESOLUTION_CLAIMER: true,
    PORTABILITY_WAITING_RESOLUTION_CLAIMER: true
  }
}
