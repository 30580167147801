import { SET_BACKOFFICE_STORE } from '../actionTypes/backoffice'

const BASE = {
  state: false,
  data: null
}

const INITIAL_STATE = {
  backoffice: BASE
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BACKOFFICE_STORE:
      return { ...state, backoffice: action.payload }
    default:
      return state
  }
}
