import React, { useEffect, useState, useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { Container } from 'reactstrap'
import { useSelector } from 'react-redux'
import { getTermsAccepted } from '../../actions/terms'
import { sendTermAccepted } from '../../actions/user'
import PAGE_CONFIG from '../../const/terms'
import { BoxDetails, CheckElement, ModalTerms } from '../../components/general-terms-component'

function Terms({ history }) {
  const { termAccepted } = useSelector((state) => state.userState)

  const [dataUserTermsAccepted, updateDataUserTermsAccepted] = useState({})
  const [requestLabel, updateRequestLabel] = useState('')
  const [checkList, updateChckList] = useState({})
  const [modalOpen, updateModalOpen] = useState(false)

  const {
    DETAILS_CONFIG,
    CHECK_LIST_CONFIG,
    LIST: { TITTLE, CONTENT, LABEL_BUTTON, LOADING, REQUEST_MSG }
  } = PAGE_CONFIG

  /**
   *
   * Search accepted commercial terms
   *
   */

  const fetchUserTermsAccepted = useCallback(
    async (id) => {
      const items = await getTermsAccepted(id)
      if (!items) {
        history.push('/login')
        toast.error(REQUEST_MSG.ERROR)
        return
      }

      updateDataUserTermsAccepted({ items })
    },
    [REQUEST_MSG.ERROR, history]
  )

  useEffect(() => {
    const userId = termAccepted && termAccepted.data.Uuid

    if (userId) {
      fetchUserTermsAccepted(userId)
    } else {
      toast.error(REQUEST_MSG.ERROR)
      history.push('/login')
    }
  }, [termAccepted, fetchUserTermsAccepted, history, REQUEST_MSG.ERROR])

  /**
   *
   * Handle check list
   *
   */

  const disabledButton = useMemo(() => Object.values(checkList).includes(false), [checkList])

  const handleCheckList = (data) => {
    updateChckList((prevstate) => ({ ...prevstate, ...data }))
  }

  /**
   *
   * Handle submit and UI return
   *
   */

  const handleSubmit = async () => {
    updateRequestLabel(LOADING)
    const response = await sendTermAccepted(termAccepted.data.Uuid)

    if (response) {
      toast.success(REQUEST_MSG.SUCCESS)
      history.push('/login')
      return
    }
    updateRequestLabel('')
    updateModalOpen(true)
  }

  return (
    <Container>
      {dataUserTermsAccepted && dataUserTermsAccepted.items && (
        <div className="terms">
          <div className="terms--header">
            <img src="/assets/imgs/logo_transparent.png" alt="Logotipo KMV" />
            <h1>
              {TITTLE.TEXT}
              <span>{TITTLE.BRAND}</span>
            </h1>
          </div>
          <div className="terms--content">
            <h2>{CONTENT.TITLE}</h2>
            <BoxDetails
              data={{
                ...DETAILS_CONFIG,
                ...dataUserTermsAccepted
              }}
            />

            <div className="terms--content--form">
              {CHECK_LIST_CONFIG.map((elm) => (
                <CheckElement {...elm} key={elm.name} action={(data) => handleCheckList(data)} />
              ))}
              <button type="button" disabled={disabledButton} onClick={() => handleSubmit()}>
                {requestLabel || LABEL_BUTTON}
              </button>
            </div>
          </div>
        </div>
      )}
      <ModalTerms isOpen={modalOpen} action={() => history.push('/login')} />
    </Container>
  )
}
Terms.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
}

export default Terms
