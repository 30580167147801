export function setSessionFlagByURLParam() {
  const searchParams = window.location.search.toUpperCase()
  ;['FLAG_PROD', 'FLAG_HOMOL', 'FLAG_DEV'].forEach((flagEnv) => {
    if (searchParams.indexOf(flagEnv) >= 0) {
      // eslint-disable-next-line no-eval
      const regex = eval(`/(?<=${flagEnv}=\\[).+?(?=\\])/g`)
      let prodFlags = searchParams.match(regex)

      prodFlags = prodFlags[0] && prodFlags[0].split(',')

      if (!prodFlags) return false

      prodFlags.forEach((flag) => {
        window.sessionStorage.setItem(`${flagEnv}_${flag}`, 'true')
      })
    }
    return true
  })
}

export function hasSessionFlag(env, flag) {
  return !!window.sessionStorage.getItem(`FLAG_${env.toUpperCase()}_${flag.toUpperCase()}`)
}
