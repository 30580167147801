import React, { Component } from 'react'

export default function IconFaq({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '32'}
      fill={color || '#000'}
      stroke={color || '#000'}
      height={size || '32'}
      viewBox="0 0 32 32"
    >
      <g transform="translate(-1097 -37)">
        <g transform="translate(20152.684 9966)">
          <g transform="translate(-19050.684 -9925)">
            <g transform="translate(0)">
              <path
                d="M30.166,13.5a11,11,0,0,0-3.35,21.479l.13,5.25L34.4,34.654A11,11,0,0,0,30.166,13.5Zm3.607,19.867-.085.035L28.3,37.43l-.088-3.543L27.7,33.75a9.574,9.574,0,1,1,6.074-.383Z"
                transform="translate(-19.166 -13.5)"
              />
            </g>
            <g transform="translate(7.903 6.179)">
              <path
                d="M41.5,32.429a3.138,3.138,0,0,0-3.051,2.934l1.087,0c0-.163.088-1.6,2.1-1.861,1.315-.171,1.982.432,2.17,1.052a1.3,1.3,0,0,1-.711,1.575,3.721,3.721,0,0,0-2.073,3.836h1.087c0-1.21.108-2.188,1.468-2.862a2.388,2.388,0,0,0,1.269-2.865A2.838,2.838,0,0,0,41.5,32.429Zm-.472,9.717h1.087V40.93H41.027Z"
                transform="translate(-38.448 -32.39)"
              />
            </g>
          </g>
        </g>
        <g opacity="0" transform="translate(1097 37)">
          <rect width={size || '32'} height={size || '32'} />
          <rect x="0.5" y="0.5" width={size || '31'} height={size || '31'} />
        </g>
      </g>
    </svg>
  )
}

IconFaq.propTypes = Component.propTypes
