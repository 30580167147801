import { toast } from 'react-toastify'
import { axiosInstance as axios } from '../lib/axios'
import Config from '../config/environments'
import { CHECK_UPLOAD_DOCUMENTS, GET_STORE, LIST_STORE, POST_STORE } from '../actionTypes/store'
import { viewModelToModel, viewsModelToModels } from '../mapper/store'
import mapStoreData from '../mapper/storeRegistration'
import { updateState } from '.'

export const getDataStore = (query = '') => async (dispatch) => {
  const base = {
    type: LIST_STORE,
    payload: {}
  }

  base.state = true

  const url = Config.STORE.URL + query

  axios
    .get(url)
    .then((response) => {
      base.payload = updateState(response)
      base.total = base.payload.data.total
      base.payload.data = viewsModelToModels(base.payload.data)
    })
    .catch((error) => {
      base.payload = error
    })
    .finally(() => {
      dispatch(base)
    })

  dispatch(base)
}

export const getDataStoreByCnpjOrName = async (filter = '', page, quantity) => {
  const url = `${Config.STORE.FILTER}filter=${filter}&page=${page}&quantity=${quantity}`

  return await axios
    .get(url)
    .then(({ data }) => data)
    .catch((error) => error)
}

export const getDataStoreByUuid = (storeByUuid) => async (dispatch) => {
  const base = {
    type: GET_STORE,
    payload: {}
  }

  base.state = true

  const url = `${Config.STORE.URL}/${storeByUuid}`

  axios
    .get(url)
    .then((response) => {
      base.payload = updateState(response)
      base.payload.data = viewModelToModel(base.payload.data)
    })
    .catch((error) => {
      base.payload = error
    })
    .finally(() => {
      dispatch(base)
    })

  dispatch(base)
}

export const postDataStore = (data) => async (dispatch) => {
  const base = {
    type: POST_STORE,
    payload: {}
  }

  base.state = true

  const url = Config.STORE.URL

  axios
    .post(url, data)
    .then((response) => {
      base.payload = updateState(response)
    })
    .catch((error) => {
      base.payload = error
    })
    .finally(() => {
      dispatch(base)
    })

  dispatch(base)
}

export const putDataStore = (data) => async (dispatch) => {
  const base = {
    type: POST_STORE,
    payload: {}
  }

  base.state = true

  const url = `${Config.STORE.URL}/${data.uuid}`
  delete data.uuid

  axios
    .put(url, data)
    .then((response) => {
      base.payload = updateState(response)
      if (response.status >= 200 && response.status < 300)
        toast.success('Loja atualizada com sucesso')
    })
    .catch((error) => {
      base.payload = error
    })
    .finally(() => {
      dispatch(base)
    })

  dispatch(base)
}

export const checkDocuments = (storeId) => async (dispatch) => {
  const base = {
    type: CHECK_UPLOAD_DOCUMENTS,
    payload: {}
  }
  base.state = true
  const url = `${Config.STORE.URL_ACCOUNT}/${storeId}/documents?documentPath=store-documents`
  axios
    .post(url)
    .then(() => {})
    .catch(() => null)
    .finally(() => null)
  dispatch(base)
}

export const getStoreData = async (
  storesId,
  userType,
  type,
  page = 0,
  quantity = 10,
  filterDelivery = false
) => {
  try {
    const uri =
      userType === 'BACKOFFICE' && type !== 'store'
        ? Config.MY_DATA.REGISTRATION_BACKOFFICE
        : Config.MY_DATA.REGISTRATION
    const url =
      type === 'store'
        ? `${uri.replace(':storeId', storesId)}`
        : `${uri.replace('/:storeId', '')}?store_ids=${storesId}&page=${page}&quantity=${quantity}`

    const { data } = await axios.get(url)

    if (type === 'store' && filterDelivery) {
      return data
    }
    if (type === 'store') {
      return mapStoreData(data, type)
    }

    return {
      associatedList: mapStoreData(data.registrationDataList, type),
      total: data.count
    }
  } catch (e) {
    return null
  }
}

export const getVoceQPadToken = async (storeId, firstName, lastName, email) => {
  const url = Config.DELIVERY.URL
  const data = JSON.stringify({
    estabelecimento: storeId,
    nome: firstName,
    sobre_nome: lastName,
    email: email
  })
  const response = await axios.post(url, data)

  return response.data
}

export const getDownloadDirf = (document, year) => {
  const url = `${Config.DIRF.URL}${Config.DIRF.DOWNLOAD}${document}/${year}`
  const response = axios.get(url)
  return response.data
}
