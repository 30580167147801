const moment = require('moment')

export default function weekUtil() {
  const today = moment(new Date())
  const weekDay = parseInt(today.format('d'))
  let startDate
  if (weekDay === 0) {
    startDate = today.add(1, 'days')
  } else if (weekDay === 6) {
    startDate = today.add(2, 'days')
  } else {
    startDate = today.subtract(weekDay - 1, 'days')
  }
  const endDate = moment(today).add(4, 'days')
  return { startDate, endDate }
}

export function sortDaysWeek(days) {
  return days
    .sort((a, b) => a.order - b.order)
    .map(({ day }) => day)
    .join(', ')
}

export function schedulingOperationType(data) {
  return data.reduce((acc, { weekday, active }) => {
    switch (active && weekday) {
      case 'MONDAY':
        acc.push({ order: 0, day: 'segunda' })
        break
      case 'TUESDAY':
        acc.push({ order: 1, day: 'terça' })
        break
      case 'WEDNESDAY':
        acc.push({ order: 2, day: 'quarta' })
        break
      case 'THURSDAY':
        acc.push({ order: 3, day: 'quinta' })
        break
      case 'FRIDAY':
        acc.push({ order: 4, day: 'sexta' })
        break
      case 'SATURDAY':
        acc.push({ order: 5, day: 'sábado' })
        break
      case 'SUNDAY':
        acc.push({ order: 6, day: 'domingo' })
        break
      default:
        break
    }

    return acc
  }, [])
}
