import { localGet, localSet, localRemove } from '../lib/session'
import history from './history'
import { axiosInstance as axios } from '../lib/axios'
import Config from '../config/environments'
import PERMISSION from '../const/userPermission'

const MAX_IDLE_TIME = process.env.MAX_IDLE_TIME
  ? Number.parseInt(process.env.MAX_IDLE_TIME, 10)
  : Config.EXPIRATION_AUTH

const logged = () => (localGet('IS_LOGGED') ? localGet('IS_LOGGED').value : '0')

export const isLoggedIn = () => localGet('IS_LOGGED') === 'true'

export const lastAPICallTime = () => Number.parseInt(logged, 10)

export const updateLastAPICallTime = () =>
  localSet('LAST_API_CALL', new Date().getTime().toString())

const isIdle = () => new Date().getTime() - lastAPICallTime() > MAX_IDLE_TIME

export const logout = async () => {
  const url = Config.USER.LOGOUT

  localSet('IS_LOGGED', 'false')
  localRemove('AUTH_TOKEN')
  localRemove('STORES')
  localRemove('CHECK_DOCUMENTS')
  localRemove('BACKOFFICE_STORE')
  localRemove('BACKOFFICE_BRANCH')
  localRemove('TERM')

  axios.post(url)

  history.push('/login')
}

export const checkIdle = async () => {
  if (isIdle() && isLoggedIn()) {
    logout()
  }

  return Promise.resolve(true)
}

export const getPermission = (value) => ({
  readOnly: value === PERMISSION.backoffice
})
