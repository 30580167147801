import { combineReducers } from 'redux'
import BackofficeStore from './backofficeReducer'
import BranchReducer from './branchReducer'
import CampaignReducer from './campaignReducer'
import PixKeysReducer from './pixReducer'
import ReceivableReducer from './receivableReducer'
import StoreReducer from './storeReducer'
import TransactionReducer from './transactionReducer'
import UserReducer from './userReducer'

export const reducers = (asyncReducers) =>
  combineReducers({
    campaign: CampaignReducer,
    backofficeState: BackofficeStore,
    branchState: BranchReducer,
    receivableState: ReceivableReducer,
    storeState: StoreReducer,
    transactionState: TransactionReducer,
    userState: UserReducer,
    pixState: PixKeysReducer,
    ...asyncReducers
  })
