import {
  AUTH_USER,
  POST_USER,
  LIST_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_TERM_ACCEPTED,
  POST_TERM_ACCEPTED,
  RESET_USER_STATE
} from '../actionTypes/user'

const BASE = {
  state: false,
  data: null
}

const INITIAL_STATE = {
  statePostUser: BASE,
  listUser: BASE,
  authUser: BASE,
  forgotPassword: BASE,
  resetPassword: BASE
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, authUser: action.payload }
    case POST_USER:
      return { ...state, statePostUser: action.payload }
    case LIST_USER:
      return { ...state, listUser: action.payload }
    case FORGOT_PASSWORD:
      return { ...state, forgotPassword: action.payload }
    case RESET_PASSWORD:
      return { ...state, resetPassword: action.payload }
    case GET_TERM_ACCEPTED:
      return { ...state, termAccepted: action.payload }
    case RESET_USER_STATE: {
      return { ...INITIAL_STATE }
    }
    case POST_TERM_ACCEPTED:
      return { ...state, updateTermAcceptedResult: action.payload }
    default:
      return state
  }
}
