export default {
  LOADING: 'Carregando...',
  TITLE: 'Esqueci minha senha',
  LOGO_TITLE: 'Portal Parceiros',
  LOGO_SUB_TITLE: 'KMV',
  LOGIN_TEXT:
    'Insira o e-mail associado à sua conta e enviaremos um link para resetar a sua senha.',
  LOGIN_BUTTON: 'Enviar',
  FORGOT_MSG: 'Um e-mail com instruções para redefinição de senha foi enviado.',
  ERROR_FORMAT: 'Formato de e-mail inválido.'
}
