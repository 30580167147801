import { PIX_STORE_KEYS } from '../actionTypes/pix'

const BASE = {}

const INITIAL_STATE = {
  keysList: BASE,
  notification: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PIX_STORE_KEYS:
      return { ...state, keysList: action.payload, notification: action.payload.notification }
    default:
      return state
  }
}
