export default {
  items: [
    // {
    //   name: 'Delivery',
    //   url: '/delivery',
    //   icon: 'fa fa-truck-moving',
    //   badge: {
    //     variant: 'info'
    //   }
    // },
    {
      name: 'Vendas de hoje',
      url: '/vendas-hoje',
      icon: 'fa fa-shopping-basket',
      badge: {
        variant: 'info'
      }
    },
    {
      name: 'Vendas fechadas',
      url: '/vendas-fechadas',
      icon: 'fa fa-shopping-basket',
      badge: {
        variant: 'info'
      }
    },
    {
      name: 'Conta',
      url: '/conta',
      icon: 'fa fa-balance-scale',
      badge: {
        variant: 'info'
      }
    },
    {
      name: 'Conciliação',
      url: '/conciliacao',
      icon: 'fa fa-balance-scale',
      badge: {
        variant: 'info'
      }
    },
    {
      name: 'A Oferta é Sua!',
      url: '/gestao-de-campanhas',
      icon: 'fa fa-balance-scale',
      badge: {
        variant: 'info'
      }
    },
    /*     {
      name: 'Área Pix',
      url: '/conta',
      icon: 'fa fa-balance-scale',
      badge: {
        variant: 'info',
      },
    }, */
  ]
}
