import React from 'react'
// import history from '../../service/history';
import DefaultView from '../../components/defaultView'
import { Box } from '@material-ui/core'

// const itsNotProd = (window.location.hostname !== 'parceirosabasteceai.com.br' && window.location.hostname !== 'www.parceirosabasteceai.com.br');

function NotSupportedBrowser() {
  // if (itsNotProd) history.push({ pathname: '/login' });

  return (
    <DefaultView>
      <div className="pt-5 pb-4 px-5 text-primary">
        <p className="h3 pb-2 text-center">Ops...</p>
        <p>Já já você poderá fazer tudo por aqui.</p>
        <p>
          Por gentileza, entre em contato com a nossa central de atendimento para fazer a
          regularização cadastral de seu acesso.
        </p>
        <p>
          No momento da abertura do chamado, não esqueça de informar que se trata de uma
          regularização cadastral.
        </p>
        <Box display="flex" flexDirection="column" alignItems="center" mt="3rem">
          <h4>3003-3470</h4>
          <p>Segunda a sexta, das 9h às 21h</p>
          <p>Sábados das 9h às 18h</p>
        </Box>
      </div>
    </DefaultView>
  )
}

export default NotSupportedBrowser
