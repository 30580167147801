import { makeStyles } from '@material-ui/core/styles'

const darkBlue = '#003863'
const gray = '#E5E5E5'
const nunito = 'Nunito'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  title: {
    fontFamily: nunito,
    color: darkBlue,
    fontSize: '2rem',
    fontWeight: 700
  },
  accordion: {
    color: darkBlue,
    margin: '.2rem',
    borderRadius: '4px',
    '&::before': {
      opacity: 0
    }
  },
  accordionBody: {
    backgroundColor: gray,
    margin: '.15rem',
    padding: '2rem',
    borderRadius: '0 0 .5rem .5rem'
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2)
  },
  textTitle: {
    fontFamily: nunito,
    color: darkBlue,
    fontSize: '1.5rem',
    fontWeight: 700
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))
