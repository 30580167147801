import { axiosInstance as axios } from '../lib/axios'
import { formattedDataTermsAccepted } from '../mapper/terms'
import Config from '../config/environments'
import moment from 'moment'

export const getTermsAccepted = async (id) => {
  try {
    const url = `/my-data/financial/${id}`

    const response = await axios.get(url)
    return formattedDataTermsAccepted(response.data)
  } catch (e) {
    return null
  }
}

export const downloadDocument = async (document, path) => {
  const url = `${Config.USER.DOWNLOAD_DOCUMENT}?documentName=${document}&documentPath=${path}`
  const response = await axios.get(url)
  return response
}
export const downloadDocumentPerformance = async (path, document, type, date) => {
  const  newDate = moment(date).format('YYYY-MM')
  const url = `${process.env.REACT_APP_API_BASE_URL}${type === 'BACKOFFICE' ? '/backoffice' : ''}/${path}/${document}-${newDate}`
  const response = await axios.get(url)
  return response
}
