import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import RatingModal from '../components/ratingModal'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: 0,
    top: '130px',
    flexGrow: 1,
    width: '100%',
    maxWidth: '400px',
    minWidth: 300,
    transform: 'translateZ(0)',
    '@media all and (-ms-high-contrast: none)': {
      display: 'none'
    }
  },
  modal: {
    display: 'flex',
    position: 'relative !important',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '500px',
    '& div:first-child': {
      backgroundColor: 'transparent !important'
    }
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2, 3),
    width: '100%'
  }
}))

export default function FloatingModal() {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const rootRef = React.useRef(null)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root} ref={rootRef}>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={open}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
            <IconButton size="small" component="span" onClick={() => setOpen(false)}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <RatingModal isOpen handleClose={handleClose} />
        </div>
      </Modal>
    </div>
  )
}
