import React, { useEffect } from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useStyles } from './styles'
import Box from '@material-ui/core/Box'
import { questions } from './questions'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ReactGA from 'react-ga'

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props

  return (
    <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
      <Typography variant="h6" className={classes.textTitle}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

const CommonQuestions = ({ modal, handleClose, modalTitle }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  useEffect(() => {
    ReactGA.event({
      category: 'Perguntas Frequentes',
      action: 'Página Perguntas Frequentes',
    });
  }, [])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <>
      {modal ? (
        <DialogTitle classes={classes} id="customized-dialog-title" onClose={handleClose}>
          {modalTitle}
        </DialogTitle>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding=".5rem"
          m="2rem 0"
        >
          <Typography className={classes.title}>Perguntas Frequentes</Typography>
        </Box>
      )}
      {questions.map(({ title, Description, panel }) => (
        <Accordion
          className={classes.accordion}
          key={panel}
          expanded={expanded === panel}
          onChange={handleChange(panel)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              <li>{title}</li>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionBody}>
            <Description />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}

CommonQuestions.defaultProps = {
  modal: false,
  modalTitle: ''
}
CommonQuestions.propTypes = {
  modal: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string
}

export default CommonQuestions
