import React, { Component } from 'react'

export default function IconMail({ size = 22, color = 'black' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size * 0.79}
      viewBox={`0 0 ${size} ${size * 0.79}`}
    >
      <g transform="translate(-0.955 -3.25)">
        <path
          strokeWidth={size * 0.07}
          fill="none"
          stroke={color}
          d="M4,4H20a2.006,2.006,0,0,1,2,2V18a2.006,2.006,0,0,1-2,2H4a2.006,2.006,0,0,1-2-2V6A2.006,2.006,0,0,1,4,4Z"
        />
        <path strokeWidth={size * 0.07} fill="none" stroke={color} d="M22,6,12,13,2,6" />
      </g>
    </svg>
  )
}

IconMail.propTypes = Component.propTypes
