export default {
  ResponseStatus: '',
  Uuid: '',
  Email: '',
  Name: '',
  Type: 'BACKOFFICE',
  Document: '',
  Status: '',
  Password: '',
  Authorized: '',
  LoginCount: 0,
  LastFeedBack: '',
  Groups: [],
  termAccepted: false
}
