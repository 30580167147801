import React from 'react'
import history from '../../service/history'
import unsupportedBrowser from '../../lib/unsupported-browser'
import DefaultView from '../../components/defaultView'

function NotSupportedBrowser() {
  if (!unsupportedBrowser()) history.push({ pathname: '/' })

  return (
    <DefaultView>
      <div className="pt-5 pb-4 px-5 text-primary">
        <p className="h3 pb-2 text-center">Navegador não suportado</p>

        <p>Infelizmente não é possível acessar o Portal Parceiros através do Internet Explorer.</p>
        <p>
          Sugerimos a utilização do Google Chrome para uma melhor experiência.
          <a
            className="link"
            href="https://www.google.com/intl/pt-BR/chrome/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' Clique aqui '}
          </a>
          para baixá-lo.
        </p>
        <p>Caso prefira, você pode acessar através do Firefox, Safari ou Edge.</p>
      </div>
    </DefaultView>
  )
}

export default NotSupportedBrowser
