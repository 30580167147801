export default {
  LOADING: 'Carregando...',
  TITLE: 'Definir senha',
  LOGO_TITLE: 'Portal Parceiros',
  LOGO_SUB_TITLE: 'KMV',
  LOGIN_TEXT: '',
  SEND_BUTTON: 'Definir senha',
  REDIRECT_BUTTON: 'Entrar',
  FORGOT_MSG: 'Senha redefinida com sucesso.',
  DIFFERENT_PASSWORD: 'As senhas não batem',
  MINIMUM_VALUE: 'Sua senha precisa ter entre 8 e 20 caracteres',
  SENT_TITLE: 'Nova senha definida',
  SENT_TEXT: 'Nova senha definida com sucesso!'
}
