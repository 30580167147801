import { axiosInstance as axios } from '../lib/axios'
import Config from '../config/environments'
import moment from 'moment'
import { localRemove, localSet } from '../lib/session'

export const feedbackSave = async (data) => {
  const url = `${Config.FEEDBACK.URL}${Config.FEEDBACK.SAVE}`

  try {
    const response = await axios.post(url, data)

    if (response.status === 204) {
      localRemove('CHECK_FEEDBACK')
    }

    return { status: response.status }
  } catch (err) {
    return { err }
  }
}

export const saveLoginCount = async (data) => {
  const url = `${Config.TAG.URL}${Config.TAG.COUNT_SAVE}`
  try {
    const response = await axios.post(url, data)

    if (
      data.type === 'PORTAL' &&
      response.data.count >= 3 &&
      response.data.lastFeedback &&
      moment(response.data.lastFeedback) < moment().subtract(2, 'month')
    ) {
      localSet('CHECK_FEEDBACK', true, Config.EXPIRATION_AUTH)
    } else {
      localSet('CHECK_FEEDBACK', false, Config.EXPIRATION_AUTH)
    }

    return response.data
  } catch (err) {
    return { err }
  }
}
