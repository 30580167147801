export default {
  END_POINT: process.env.REACT_APP_API_BASE_URL || 'http://localhost:9010',
  EXPIRATION_AUTH: 3600000 || process.env.MAX_IDLE_TIME,
  EXPIRATION_FORM: 5,
  DEBUG: {
    LEVEL: 'debug',
    AVAILABLE: true,
    LOCAL: true
  },
  USER: {
    URL: '/security/user',
    AUTH: '/auth/login',
    LOGOUT: '/auth/logout',
    FORGOT_PASSWORD: '/reset-password/email',
    RESET_PASSWORD: '/reset-password/token',
    STORE: '/store/email',
    STORE_MERCHANT_ACCOUNT: '/store/merchant/account',
    DOWNLOAD_DOCUMENT: '/store/document',
    // DOWNLOAD_DOCUMENT_BACKOFFICE: '/store/financial/backoffice/document',
    PATH_TERM_DOCUMENT: 'terms-and-conditions',
    DOCUMENT_TERM_NAME: 'KMV - Regulamento Estabelecimentos (Aceite App)_v4_20200731.pdf',
    DOCUMENT_PERFORMANCE: 'KMV - Performance (Aceite App)_v4_20200731.pdf',
    DOCUMENT_TERM_NAME_NOT_EXTENSION:
      'KMV - Regulamento Estabelecimentos (Aceite App)_v4_20200731',
    PATH_USER_GUIDE_DOCUMENT: 'terms-and-conditions',
    DOCUMENT_USER_GUIDE: 'Roteiro de Contabilização Oficial_2021.pdf',
    DEFAULT: '/user',
    PERFORMANCE: 'one-page-report/ec',
  },
  RECEIVABLE: {
    URL: '/receivable/',
    FILTER: '/receivable/filter',
    DOWNLOAD: '/receivable/download',
    INSTALLMENTS_DOWNLOAD: '/receivable/installments/download',
    INSTALLMENTS: '/receivable/installments',
    SUMMARY: '/receivable/installments/abstract',
    PERIOD: '/receivable/period',
    CHECK: '/receivable/check'
  },
  STORE: {
    URL: '/store',
    URL_ACCOUNT: '/store/account',
    REGISTRATION: '/store/my-data/registration',
    REGISTRATION_FINANCIAL: '/my-data/registration/financial',
    REGISTRATION_BACKOFFICE: '/store/backoffice/my-data/registration',
    FILTER: '/store/filter?',
    INTEGRATORS: '/store/integrators',
    TOKENVOCEQPAD: '/store/checkvoceqpad',
    BRANCH: '/store/branches',
    BACKOFFICE_BRANCHES: '/backoffice/store/branches'
  },
  TRANSACTION: {
    URL: '/transaction',
    DOWNLOAD: '/transaction/download',
    DAILY: '/transaction/daily',
    DAILY_CHECK: '/transaction/daily/check',
    SUMMARY_BY_DATE: '/transaction/by-date/summary',
    LIST_BY_DATE: '/transaction/by-date/list',
    TRANSACTION_TO_TRANSACTION_TABLE: '/transaction/transaction-to-transaction/table',
    TRANSACTION_TO_TRANSACTION_SUMMARY: '/transaction/transaction-to-transaction/summary'
  },
  BACKOFFICE: {
    URL: '/backoffice',
    BRANCHES: '/backoffice/branches'
  },
  PIX: {
    URL: '/pix',
    STORE_KEYS: '/pix/store-keys',
    FAVORITE_KEY: '/pix/favorite-key',
    CLAIM: '/pix/claim',
    REFUSE_KEY: '/pix/refused',
    CANCEL_CLAIM: '/pix/stores/:store_uuid/keys/:key_uuid/claim/cancel',
    CONFIRM_CLAIM: '/pix/stores/:store_uuid/keys/:key_uuid/claim/confirm',
    CLOSE_PIX_SALES: '/closed-pix-sales/transactions-pix',
    CLOSE_PIX_SALES_BACKOFFICE: 'backoffice/closed-pix-sales/transactions-pix'
  },
  DIRF: {
    URL: '/my-data/report-income/dirf',
    DOWNLOAD: '/download/'
  },
  DELIVERY: {
    URL: '/delivery'
  },
  GOOGLE_ANALYTICS: {
    URL: '/ga/tracking-id'
  },
  INVOICE_TYPES: {
    URL: '/my-data/invoice/types'
  },
  INVOICES: {
    URL: '/invoice'
  },
  SALE_TO_SALE: {
    TABLE: '/sale-to-sale/table',
    SUMMARY: '/sale-to-sale/table/summary',
    TABLE_DOWNLOAD: '/sale-to-sale/table/download'
  },
  TODAY_SALES: {
    TABLE: '/today-sales/table',
    SUMMARY: '/today-sales/table/summary'
  },
  CLOSED_SALES: {
    TABLE_LINE_DOWNLOAD: '/closed-sales/table/line/download',
    TABLE_LINE_DOWNLOAD_CSV: '/closed-sales/table/line/download-csv',
    TABLE_LINE_CHECK: '/closed-sales/table/line/check',
    TABLE_DOWNLOAD: '/closed-sales/table/download',
    TABLE_DOWNLOAD_CSV: '/closed-sales/table/download-csv',
    TABLE_DOWNLOAD_CSV_DETAILED: '/closed-sales/table/download-csv/detailed',
    TABLE: '/closed-sales/table'
  },
  SALES_DETAILS: {
    DETAILS: '/sale-details/details',
    DETAILS_BACKOFFICE: '/backoffice/sale-details/details'
  },
  ACCOUNT: {
    TABLE: '/account/table',
    TABLE_DOWNLOAD: '/account/table/download',
    TABLE_LINE_DOWNLOAD: '/account/table/line/download',
    TABLE_LINE_CHECK: '/account/table/line/check',
    BALANCE: '/account/store/:uuid/balance',
    BALANCE_BACKOFFICE: '/backoffice/account/store/:uuid/balance'
  },
  MY_DATA: {
    REGISTRATION: '/my-data/registration/stores/:storeId',
    REGISTRATION_BACKOFFICE_LIST: '/my-data/registration/backoffice/stores',
    REGISTRATION_BACKOFFICE_SINGLE: '/my-data/registration/backoffice/store',
    FINANCIAL: '/my-data/financial',
    INVOICE_TYPES: '/my-data/invoice/types',
    INVOICES: '/my-data/invoice',
    INVOICES_BACKOFFICE: '/backoffice/invoice',
    DIRF_DOWNLOAD: '/my-data/report-income/dirf/download',
    OPTIN: '/optin',
    TERM_FILE: '/optin',
  },
  FEEDBACK: {
    URL: '/feedback',
    SAVE: '/save'
  },
  TAG: {
    URL: '/tag',
    COUNT_SAVE: '/login/count'
  }
}
