export default {
  Uuid: '',
  FantasyName: '',
  Name: '',
  Active: false,
  Booking: false,
  Integrator: false,
  HeadOffice: false,
  Online: false,
  IPP: false,
  Delivery: false,
  PvCorp: null,
  An8: '',
  Gv: ''
}
