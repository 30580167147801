import { sortDaysWeek, schedulingOperationType } from '../lib/weekUtil'
import PAGE_CONFIG from '../const/terms'

function parseTedDescription(type, tedOption, weekday) {
  if (type === 'CASH' && tedOption === 'SAME_AS_PAYMENT') {
    return PAGE_CONFIG.LIST.CONTENT.LABEL_CASH
  }
  if (tedOption === 'SAME_AS_PAYMENT') {
    return PAGE_CONFIG.LIST.CONTENT.LABEL_SAME_AS_PAYMENT
  }

  return `Toda ${sortDaysWeek(schedulingOperationType([{ weekday }]))} de cada semana`
}

function parseBalanceTranfer(operationType, paymentType, waitDays, splits) {
  if (operationType === 'AFTER_DAYS') {
    return `D+${waitDays || 0}`
  }

  return `Toda ${sortDaysWeek(schedulingOperationType(splits))} de cada semana`
}

function propsFormatted(
  {
    method,
    percentageCommission,
    waitDays,
    operationType,
    splits,
    paymentMethodName,
    commissionBenefit,
  },
  { tedOption, weekDay: weekday },
) {

  const type = method.name
  const comissionsLabel = commissionBenefit === 'CASHBACK' ? 'Cashback' : 'Acúmulo de Km'
  const commissions =  `${percentageCommission}%`
  const saleOff = parseBalanceTranfer(operationType, method.name, waitDays, splits)
  const tedDescription = parseTedDescription(paymentMethodName, tedOption, weekday)

  return {
    type,
    comissionsLabel,
    commissions,
    saleOff,
    tedDescription,
  }
}

export function formattedDataTermsAccepted({ paymentMethods, transfer, storePenetration }) {
  const paymentMethod = paymentMethods.reduce((acc, elm) => {
    acc.push(propsFormatted(elm, transfer))
    return acc
  }, [])

  return {
    paymentMethods: paymentMethod,
    storePenetration
  }
}
